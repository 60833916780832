import { Skeleton } from "@mui/material";
import React from "react";

function SkelitonViewer({ numberOfSkeliton }) {
  for (let index = 0; index < numberOfSkeliton; index++) {
    return <Skeleton variant="rounded" animation="wave" height={30} />;
  }
}

function SkelitonLoader({ numberOfSkeliton = 5 }) {
  return (
    <div style={{ display: "grid", columnGap: 2 }}>
      {<SkelitonViewer numberOfSkeliton={numberOfSkeliton} />}
    </div>
  );
}

export default SkelitonLoader;
