import React, { useState } from "react";
import {
  Button,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import SkillsBubble from "./SkillsBubble";
import SkillTree from "./SkillTree";

function SkillsChartTabPanel({ propsValue }) {
  const { reportFigures, skills } = propsValue;
  const [selectedSkill, setSelectedSkill] = useState(null);

  // Transforming data from reportFigures
  const transformedData = Object.entries(
    reportFigures?.leadCountBySkills || {}
  ).map(([skillName, value]) => ({
    skillName, // Using skillName directly as it is
    value: Math.floor(value), // Ensure value is an integer
  }));
  console.log(skills, "transformedData");
  const handleBubbleClick = (skillName) => {
    console.log("Clicked skill:", skillName);
    setSelectedSkill(skillName); // Set the clicked skill as the selected skill
  };

  const handleBackToChart = () => {
    setSelectedSkill(null); // Reset the selected skill to go back to the chart
  };

  const handleSkillFilterChange = (event) => {
    const selectedName = event.target.value;
    if (selectedName) {
      setSelectedSkill(selectedName);
    }
  };
  return (
    <div>
      <Grid container spacing={2}>
        {selectedSkill ? (
          <Grid item xs={12}>
            {" "}
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", flexDirection: "row-reverse" }}
              >
                <Button variant="outlined" onClick={handleBackToChart}>
                  Back to Bubble Chart
                </Button>
              </Grid>
              <Grid item xs={12}>
                <SkillTree
                  skillName={selectedSkill}
                  setSkillName={setSelectedSkill}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <FormControl
              fullWidth
              variant="outlined"
              margin="normal"
              sx={{ m: 1, width: 300 }}
            >
              <InputLabel>Skills</InputLabel>
              <Select
                labelId="skill-filter-label"
                value={selectedSkill || ""}
                onChange={handleSkillFilterChange}
                label="Filter Skills"
              >
                {skills.map((skill) => (
                  <MenuItem key={skill.name} value={skill.name}>
                    {skill.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <SkillsBubble
              data={transformedData}
              onBubbleClick={handleBubbleClick}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default SkillsChartTabPanel;
