// material
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  Grid,
  Paper,
  CardContent,
  Box,
  Tabs,
  Tab,
  Badge,
  TableHead,
  TablePagination,
} from "@mui/material";
// components
import Page from "../UI/Page";
import DashboardAction from "./DashboardAction";
import * as React from "react";
import { styled } from "@mui/material/styles";

import { tableCellClasses } from "@mui/material/TableCell";

import { ActionContext } from "../../context/action.context";
import NoDataViewer from "../UI/NoDataViewer";
import SkelitonLoader from "../UI/SkelitonLoader";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function DashboardActionList(props) {
  const {
    actionList = [],
    fetchActions,
    separatedActions = {},
    benchedResources = [],
    isLoading = {},
  } = props;
  const { setRowColor } = React.useContext(ActionContext);
  const [tabValue, setTabValue] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#e3f2fd",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#ede7f6",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const handleRowClick = (event) => {
    const backgroundColor = window
      .getComputedStyle(event.currentTarget)
      .getPropertyValue("background-color");
    setRowColor(() => backgroundColor);
  };

  return (
    <Page title="User">
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Card sx={{ height: 450 }}>
                  <CardContent style={{ padding: 15 }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        value={tabValue}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                      >
                        <Tab
                          label={
                            <Badge
                              badgeContent={separatedActions.dueToday.length}
                              color="primary"
                            >
                              <p>Due Today</p>
                            </Badge>
                          }
                          {...a11yProps(0)}
                        />
                        <Tab
                          label={
                            <Badge
                              badgeContent={
                                separatedActions.pendingUntilToday.length
                              }
                              color="error"
                            >
                              <p>Pending Until Today</p>
                            </Badge>
                          }
                          {...a11yProps(1)}
                        />
                      </Tabs>
                    </Box>
                    <CustomTabPanel value={tabValue} index={0}>
                      {isLoading.forAction ? (
                        <SkelitonLoader numberOfSkeliton={10} />
                      ) : !separatedActions.dueToday.length ? (
                        <NoDataViewer
                          style={{
                            height: "300px",
                            width: "300px",
                          }}
                        />
                      ) : (
                        <TableContainer
                          sx={{ maxHeight: 340, overflow: "auto" }}
                          component={Paper}
                        >
                          <Table aria-label="customized table">
                            <TableBody>
                              {
                                // actionList.map((row, index) => {
                                // return dateMatch(row.dueDate) ? (
                                separatedActions?.dueToday.map(
                                  (row, index) => (
                                    <>
                                      <StyledTableRow
                                        key={index}
                                        onClick={handleRowClick}
                                      >
                                        <StyledTableCell
                                          component="th"
                                          scope="row"
                                          style={{
                                            padding: 10,
                                            cursor: "pointer",
                                          }}
                                        >
                                          <DashboardAction
                                            id={row.contactId}
                                            leadId={row.leadId}
                                            projectId={row.projectId}
                                            actionId={row._id}
                                            isDone={row.isDone}
                                            doneOn={row.doneOn}
                                            doer={row.doer}
                                            doneBy={row.doneBy}
                                            targetKey={row._id}
                                            toDo={row.toDo}
                                            dueDate={row.dueDate}
                                            targetType={row.targetType}
                                            targetInfo={row.targetInfo}
                                            actionType={row.actionType}
                                            fetchActions={fetchActions}
                                            contactName={row.contactName}
                                            leadSummary={row.leadSummary}
                                            projectTitle={row.projectTitle}
                                            companyId={row.companyId}
                                            companyName={row.companyName}
                                          ></DashboardAction>
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    </>
                                  )
                                  // ) : null;
                                )
                              }
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                    </CustomTabPanel>
                    <CustomTabPanel value={tabValue} index={1}>
                      {isLoading.forResource ? (
                        <SkelitonLoader numberOfSkeliton={10} />
                      ) : !separatedActions.pendingUntilToday.length ? (
                        <NoDataViewer
                          style={{
                            height: "300px",
                            width: "300px",
                          }}
                        />
                      ) : (
                        <TableContainer
                          sx={{ maxHeight: 340, overflow: "auto" }}
                          component={Paper}
                        >
                          <Table aria-label="customized table">
                            <TableBody>
                              {
                                // actionList.map((row, index) => {
                                // return pastDateMatch(row.dueDate) ? (
                                separatedActions?.pendingUntilToday.map(
                                  (row, index) => (
                                    <>
                                      <StyledTableRow
                                        key={index}
                                        onClick={handleRowClick}
                                      >
                                        <StyledTableCell
                                          component="th"
                                          scope="row"
                                          style={{
                                            padding: 10,
                                            cursor: "pointer",
                                          }}
                                        >
                                          <DashboardAction
                                            id={row.contactId}
                                            leadId={row.leadId}
                                            projectId={row.projectId}
                                            actionId={row._id}
                                            isDone={row.isDone}
                                            doneOn={row.doneOn}
                                            doer={row.doer}
                                            doneBy={row.doneBy}
                                            targetKey={row._id}
                                            toDo={row.toDo}
                                            dueDate={row.dueDate}
                                            targetType={row.targetType}
                                            targetInfo={row.targetInfo}
                                            actionType={row.actionType}
                                            fetchActions={fetchActions}
                                            contactName={row.contactName}
                                            leadSummary={row.leadSummary}
                                            projectTitle={row.projectTitle}
                                            companyId={row.companyId}
                                            companyName={row.companyName}
                                          ></DashboardAction>
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    </>
                                  )
                                  // ) : null;
                                )
                              }
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                    </CustomTabPanel>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={6}>
                <Card sx={{ height: 450 }}>
                  <CardContent style={{ padding: 15 }}>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">Resource Name</TableCell>
                            <TableCell align="left">Skills</TableCell>
                            <TableCell align="left">Joined On</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {isLoading.forResource ? (
                            <TableRow>
                              <TableCell colSpan={3}>
                                <SkelitonLoader numberOfSkeliton={10} />
                              </TableCell>
                            </TableRow>
                          ) : !benchedResources?.length ? (
                            <TableRow>
                              <TableCell colSpan={3} rowSpan={3}>
                                <NoDataViewer
                                  style={{
                                    height: "300px",
                                    width: "300px",
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          ) : (
                            benchedResources
                              .slice(page * 5, page * 5 + 5)
                              .map(
                                (
                                  { _id, name, joiningDate, skills = [] },
                                  index
                                ) => (
                                  <TableRow hover key={_id}>
                                    <TableCell align="left">{name}</TableCell>
                                    <TableCell align="left">
                                      {skills?.join(", ")}
                                    </TableCell>
                                    <TableCell align="left">
                                      {new Date(joiningDate).toDateString()}
                                    </TableCell>
                                  </TableRow>
                                )
                              )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[5]}
                      component="div"
                      count={benchedResources.length}
                      rowsPerPage={5}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={() => {}}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
