import React, { useEffect, useState } from "react";
import Page from "../components/UI/Page";
import { Card, CardContent, Container, Grid, Typography } from "@mui/material";
import InvoiceReportFilterOptions from "../components/Reports/InvoiceReportFilterOptions";
import { getUsersByRoles } from "../services/users.service";
import AccountsReportTable from "../components/Reports/AccountsReportTable";
import { toast } from "react-toastify";
import { getAccountReport } from "../services/report.service";
import { createAndDownloadExcelFile } from "../utils/excel.utils";

const allMonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function AccountsReport() {
  const [state, setState] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
    salesOwners: [],
    users: [],
  });

  const [accountReports, setAccountReports] = useState([]);

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    if (state.users.length > 0) {
      setState((prev) => ({
        ...prev,
        salesOwners: state.users.map((user) => user._id), // Select all users by default
      }));
    }
  }, [state.users]);

  const handleChange = (event) => {
    let {
      target: { name, value },
    } = event;
    if (Array.isArray(value)) {
      if (state.users.length < value.length) {
        setState((prev) => ({
          ...prev,
          [name]: [],
        }));
        return;
      }

      if (value?.includes("ALL")) {
        let allIds = state.users.reduce((accumulator, user) => {
          accumulator.push(user._id);
          return accumulator;
        }, []);
        setState((prev) => ({
          ...prev,
          [name]: allIds,
        }));
        return;
      }
    }
    if (name === "month") {
      const monthIndex = allMonths.indexOf(value);
      if (monthIndex != -1) {
        setState((prev) => ({ ...prev, [name]: monthIndex }));
        return;
      }
    }
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const provideMonthAndYearListing = (type) => {
    switch (type) {
      case "month":
        if (state.year < new Date().getFullYear()) {
          return allMonths;
        }
        return allMonths.slice(0, new Date().getMonth() + 1);
        break;

      case "year":
        let numberOfIndex = 0;
        let years = [];
        while (!years.includes(2022)) {
          years.push(new Date().getFullYear() - numberOfIndex);
          numberOfIndex++;
        }
        return years;
        break;
      default:
        return [];
        break;
    }
  };

  const renderName = (userIds) => {
    if (state.users.length == state.salesOwners.length) return ["ALL"];
    const names = state.users.reduce((acc, user) => {
      if (userIds?.includes(user?._id + "")) {
        acc.push(user.name);
      }
      return acc;
    }, []);
    return names.join(", ");
  };

  const formatMonthAndYear = () => {
    let { month, year } = state;
    return {
      from: new Date(`01-${allMonths[month]}-${year}`),
      to: new Date(`31-${allMonths[month]}-${year}`),
    };
  };

  const fetchAccountReports = async () => {
    try {
      if (!state.salesOwners.length) {
        toast("Sales Owner can not be empty");
        return;
      }
      const interval = formatMonthAndYear(state.interval);
      const response = await getAccountReport(interval, state.salesOwners);
      if (response.data.status) {
        setPage(0);
        if (response.data.data?.length) {
          setAccountReports([...response.data.data]);
          return;
        }
        setAccountReports([]);
        toast("No Invoice Reports Found");
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function onDownloadButtonClick() {
    try {
      const column = [
        {
          header: "Period",
          key: "period",
          width: 25,
        },
        {
          header: "Date",
          key: "date",
          width: 25,
        },
        {
          header: "Invoice No",
          key: "invoiceNo",
          width: 25,
        },
        {
          header: "Customer ID",
          key: "customerID",
          width: 25,
        },
        {
          header: "Customer Name",
          key: "customerName",
          width: 25,
        },
        {
          header: "GST Number",
          key: "gstNumber",
          width: 25,
        },
        {
          header: "Currency",
          key: "currency",
          width: 25,
        },
        {
          header: "Amount",
          key: "amount",
          width: 25,
        },
        {
          header: "Outstanding Amount",
          key: "outstandingAmount",
          width: 25,
        },
        {
          header: "IGST",
          key: "igst",
          width: 25,
        },
        {
          header: "CGST",
          key: "cgst",
          width: 25,
        },
        {
          header: "SGST",
          key: "sgst",
          width: 25,
        },
        {
          header: "Total Amount",
          key: "totalAmount",
          width: 25,
        },
        {
          header: "Paid Amount",
          key: "paidAmount",
          width: 25,
        },
        {
          header: "Status",
          key: "status",
          width: 25,
        },
      ];
      const rows = accountReports.map((report) => {
        return {
          period: report.monthOfInvoice ?? "N/A",
          date: new Date(report.invoiceDate ?? "").toDateString(),
          invoiceNo: report.invoiceNo ?? "N/A",
          customerID: report.customerID ?? "N/A",
          customerName: report.customerName ?? "N/A",
          gstNumber: report.gstNumber ?? "N/A",
          currency: report.currency ?? "N/A",
          amount: report.amount ?? "N/A",
          outstandingAmount: report.outstandingAmount ?? "N/A",
          igst: report.igst ?? 0,
          cgst: report.cgst ?? 0,
          sgst: report.sgst ?? 0,
          totalAmount: report.amountTotal ?? "N/A",
          paidAmount: report.paid ?? "N/A",
          status: report.isCancelled
            ? "Cancelled"
            : report.dueAmount
            ? "Pending"
            : "Paid",
        };
      });
      await createAndDownloadExcelFile(
        column,
        rows,
        `${state.year}-${allMonths[state.month]}-Accounts-Report`
      );
    } catch (error) {
      toast("Download Failed");
    }
  }

  useEffect(() => {
    (async () => {
      try {
        const response = await getUsersByRoles(3);
        if (response.data.status) {
          setState((prev) => ({ ...prev, users: [...response.data.data] }));
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <Page title="Accounts Report">
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <InvoiceReportFilterOptions
                  allMonths={allMonths}
                  state={state}
                  monthYearListing={provideMonthAndYearListing}
                  handleChange={handleChange}
                  renderName={renderName}
                  handleFetch={fetchAccountReports}
                  fromAccountsReport={true}
                  onDownloadButtonClick={onDownloadButtonClick}
                  downloadButtonDiasble={!!accountReports.length}
                />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card variant="outlined" sx={{ paddingLeft: 2, paddingRight: 2 }}>
              {accountReports.length ? (
                <AccountsReportTable
                  accountReports={accountReports}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                />
              ) : (
                <Typography variant="h6" textAlign="center" m={"20px"}>
                  No Account Reports
                </Typography>
              )}
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default AccountsReport;
