import { filter } from "lodash";
import { useState } from "react";
import Teams from "../Teams/Teams";

// material
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  CardContent,
} from "@mui/material";
// components
import Scrollbar from "../UI/Scrollbar";
// mock

import CreateTeam from "./CreateTeam";

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function TeamSection({
  projectId,
  resource,
  setResource,
  teamSingle,
  setTeamSingle,
  setIsProjectFetched,
  currentUser,
  project,
  setIactionedApiCalled,
}) {
  console.log("Loaded actions", resource);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  console.log("setIsProjectFetched", setIsProjectFetched);

  if (resource.length > 0) {
    const filteredUsers = applySortFilter(
      resource,
      getComparator(order, orderBy),
      filterName
    );
  }

  return (
    <>
      <Card elevation={3} variant="outlined">
        <CardContent>
          <CreateTeam
            teamSingle={teamSingle}
            setTeamSingle={setTeamSingle}
            projectId={projectId}
            resource={resource}
            setResource={setResource}
            setIsProjectFetched={setIsProjectFetched}
            currentUser={currentUser}
            project={project}
            setIactionedApiCalled={setIactionedApiCalled}
          />
          <div>
            <br />
          </div>
          <Scrollbar>
            <TableContainer>
              <Table>
                {resource.length > 0 ? (
                  <TableBody>
                    {resource
                      .filter((res) => res.isActive)
                      .map((row, index) => {
                        const isItemSelected =
                          selected.indexOf(row.name) !== -1;

                        return (
                          <TableRow
                            key={index}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell>
                              <Teams
                                key={index}
                                index={index}
                                projectId={projectId}
                                row={row}
                                setIsProjectFetched={setIsProjectFetched}
                                setResource={setResource}
                                project={project}
                                currentUser={currentUser}
                                setIactionedApiCalled={setIactionedApiCalled}
                              ></Teams>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {/* {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )} */}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        {/* <SearchNotFound searchQuery={filterName} /> */}
                        No Resources Available
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </CardContent>
      </Card>
    </>
  );
}
