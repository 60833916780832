import ExcelJs from "exceljs";
import { saveAs } from "file-saver";

async function createAndDownloadExcelFile(
  column = [],
  rows = [],
  workSheetName = "Contacts"
) {
  try {
    // Create a new Excel workbook
    const workbook = new ExcelJs.Workbook();
    // Add a new worksheet
    const worksheet = workbook.addWorksheet(workSheetName);
    // Providing the columns headers
    worksheet.columns = column;
    // Add data to the worksheet
    rows.forEach((row) => {
      worksheet.addRow(row);
    });
    // Add some styles in column
    worksheet.getRow(1).font = { bold: true };
    // Convert to buffer
    const buffer = await workbook.xlsx.writeBuffer();
    // Create a Blob from the buffer
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    // Save the Blob as a file
    saveAs(blob, `${workSheetName}.xlsx`);
  } catch (error) {
    console.log("error excel", error);
    throw error;
  }
}

export { createAndDownloadExcelFile };
