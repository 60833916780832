import React from "react";
import noDataImage from "../../images/undraw_no-data_ig65.png";

function NoDataViewer({ ...others }) {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "grid",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <img {...others} src={noDataImage} alt="No Data Found" />
      <h4>Oops! There's nothing here yet.</h4>
    </div>
  );
}

export default NoDataViewer;
