const apis = require("./api.requests");

const TAG = "contacts.service";
const path = "/contacts/v1";
const scanPath = "/scan/v1";

async function createContact(body) {
  return await apis.hitPostApi(`${path}/`, body);
}

async function getContacts(page) {
  return await apis.hitPagedApi(`${path}/all`, page);
}

async function getContactById(contactId) {
  return await apis.hitGetApi(`${path}/${contactId}/details`);
}

async function updateContact(contactId, body) {
  return await apis.hitPutApi(`${path}/${contactId}`, body);
}

async function removeContact(contactId) {
  return await apis.hitDeleteApi(`${path}/${contactId}`);
}

async function createComment(contactId, body) {
  //localhost:3022/api/contacts/v1/62833327c57ddaf4c4c6fcc1/comments
  const { comment, createdBy, cleanedContent } = body;
  let comments = {
    comment: cleanedContent,
    createdBy,
  };
  var formData = new FormData();
  let indexes = [];
  let descriptionFiles = [];
  formData.append("contactId", contactId);

  if (body.files && body.files.length > 0) {
    body.files.map((ele, index) => {
      console.log("ele", ele);
      if (ele.checked) {
        indexes.push(index);
      }
      if (ele.description && ele.description.length) {
        descriptionFiles.push({
          fileIndex: index,
          description: ele.description,
        });
      }
      formData.append("docFile", ele);
    });
  } else {
    formData.append("docFile", []);
    console.log("No files to process");
  }

  formData.append("checkedIndexes", JSON.stringify(indexes));
  formData.append("descriptionFiles", JSON.stringify(descriptionFiles));
  formData.append("comment", JSON.stringify(comments));
  return await apis.hitPostApi(`${path}/${contactId}/comments`, formData);
}

async function getComments(contactId, page) {
  return await apis.hitPagedApi(`${path}/${contactId}/comments`, page);
}

async function createAction(contactId, body) {
  console.log("Create action body", body);
  return await apis.hitPostApi(`${path}/${contactId}/actions`, body);
}

async function getActions(contactId, page) {
  return await apis.hitPagedApi(`${path}/${contactId}/actions`, page);
}

async function createStatus(contactId) {
  return await apis.hitPostApi(`${path}/${contactId}/statuses`, contactId);
}

async function getStatuses(contactId, page) {
  return await apis.hitPagedApi(`${path}/${contactId}/statuses`, page);
}

async function setContactAction(contactId, actionId, date) {
  //localhost:3022/api/contacts/v1/629efce3442335eefb355a48/actions/629efce3442335eefb355a4b/markAsDone
  return await apis.hitPostApi(
    `${path}/${contactId}/actions/${actionId}/markAsDone`,
    date
  );
}

async function getLeads(page, sortBySwitch) {
  return await apis.hitPagedApi(`${path}/leads/v1/all`, page, sortBySwitch);
}

async function getContactSearch(key, value) {
  return await apis.hitGetApi(
    `${path}/search?searchKey=${key}&searchValue=${encodeURIComponent(value)}`,
    value
  );
}

async function updateWatcher(id, data) {
  return await apis.hitPutApi(`${path}/${id}/createWatcher`, data);
}

// localhost: 3022 / api / contacts / v1 / 62fcb2a555f52dc43339e97f / getActionDoersByContactId

async function getActionDoersByContactId(contactId) {
  return await apis.hitGetApi(
    `/${path}/${contactId}/getActionDoersByContactId`
  );
}

async function getContactAllLeads(contactId) {
  return await apis.hitGetApi(`/${path}/${contactId}/leadsData`);
}
// http://localhost:3022/api/contacts/v1/watcherLeadList
async function getWatcherLeadList() {
  return await apis.hitGetApi(`/${path}/watcherLeadList`);
}
async function getSourceList(companyName) {
  return await apis.hitGetApi(
    `/${path}/getSourceList?companyName=${encodeURIComponent(companyName)}`
  );
}

//FETCH COUNTRIES WHICH ARE INVOLVED IN CONTACTS
const getIncludedCountries = async () => {
  return await apis.hitGetApi(`/${path}/fetchInvolvedCountries`);
};
// FETCH COMPANY NAME
const getCompanyNames = async () => {
  return await apis.hitGetApi(`/${path}/company-name`);
};

async function scanDoc(userId, files) {
  var formData = new FormData();

  formData.append("userId", userId);

  if (files && files.length > 0) {
    files.forEach((ele, index) => {
      console.log("ele", ele);

      formData.append("docFile", ele);
    });
  } else {
    formData.append("docFile", []);
    console.log("No files to process");
  }

  return await apis.hitPostApi(`${path}/scan-doc/${userId}`, formData);
}

async function getAllScanVisitingCard(userId) {
  return await apis.hitGetApi(`${path}/scan-doc/${userId}/all`);
}

//localhost:3022/api/scan/v1
async function scanVisistingcard(data) {
  return await apis.hitPostApi(`${scanPath}`, data);
}

async function getfilteredContacts(from, to) {
  return await apis.hitGetApi(`${path}/filteredContacts?from=${from}&to=${to}`);
}

async function fetchContactEmails() {
  return await apis.hitGetApi(`${path}/emails`);
}
async function fetchfilterContactEmails(
  country,
  from = null,
  to = null,
  searchValue = null
) {
  return await apis.hitGetApi(
    `${path}/emails/countryFilter?countries=${country}&from=${from}&to=${to}&searchValue=${searchValue}`
  );
}

async function getLinkedInUseDetails(linkedInProfileURL) {
  return await apis.hitPostApi(path + "/linkdIn/getDetails", {
    linkedInProfileURL,
  });
}

async function uploadExcelFileApi(data) {
  return await apis.hitPostApi(`/scan/v1/xlfile`, data);
}

async function transforeSalesOwner(data = {}) {
  return await apis.hitPutApi(`${path}/transfer/salesOwner`, data);
}

export {
  path,
  createContact,
  getContacts,
  getContactById,
  updateContact,
  removeContact,
  createComment,
  getComments,
  createAction,
  getActions,
  createStatus,
  getStatuses,
  setContactAction,
  getLeads,
  getContactSearch,
  updateWatcher,
  getActionDoersByContactId,
  getContactAllLeads,
  getWatcherLeadList,
  getSourceList,
  getIncludedCountries,
  getCompanyNames,
  scanDoc,
  getAllScanVisitingCard,
  scanVisistingcard,
  getfilteredContacts,
  fetchContactEmails,
  fetchfilterContactEmails,
  getLinkedInUseDetails,
  uploadExcelFileApi,
  transforeSalesOwner,
};
