import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Paper,
  TextField,
  Typography,
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { toast } from "react-toastify";
import InsightsIcon from "@mui/icons-material/Insights";
import {
  getResources,
  createResource,
  updateResource,
  deleteResource,
  searchResource,
} from "../../services/resources.service";
import ProjectInvolvementView from "../../components/Teams/ProjectInvolvement.View";
import DeleteConfirmationDialog from "../../components/Dialogs/DeleteConfirmationDialog";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import RemoveIcon from "@mui/icons-material/Remove";
import PersonIcon from "@mui/icons-material/Person";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import { getAllSkills } from "../../services/common.service";
import moment from "moment";

function ResourceTab() {
  const [isLoading, setIsLoading] = useState(false);
  const [resources, setResources] = useState([]);
  const [search, setSearch] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState({
    name: "",
    basePriceMonth: "",
    basePriceWeek: "",
    basePriceDay: "",
    basePriceHour: "",
    status: true,
    joiningDate: null,
    priorExperience: "",
    skills: [],
  });
  const [approvedSkills, setApprovedSkills] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalResources, setTotalResources] = useState(0);
  const [viewOnly, setViewOnly] = useState(false);
  const [projectInvolvementState, setProjectInvolvementState] = useState({
    openDialog: false,
    projectDetails: [],
    toOpen: (projectDetails = []) => {
      setProjectInvolvementState((prev) => ({
        ...prev,
        openDialog: true,
        projectDetails,
      }));
    },
    toClose: () => {
      setProjectInvolvementState((prev) => ({
        ...prev,
        openDialog: false,
        projectDetails: [],
      }));
    },
  });
  const [deleteDialogState, setDeleteDialogState] = useState({
    open: false,
    delatableDataId: "",
    toOpen: (delatableDataId) => {
      setDeleteDialogState((prev) => ({
        ...prev,
        open: true,
        delatableDataId,
      }));
    },
    toClose: () => {
      setDeleteDialogState((prev) => ({
        ...prev,
        open: false,
        delatableDataId: "",
      }));
    },
  });

  useEffect(() => {
    fetchApprovedSkills(); // Fetch only once when the component mounts
  }, []);

  const fetchApprovedSkills = async () => {
    try {
      const response = await getAllSkills("APPROVED");
      if (response.data.status) {
        setApprovedSkills(response.data?.data?.skills ?? []);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchResources();
  }, [search, page, rowsPerPage]);

  const fetchResources = async () => {
    try {
      setIsLoading(true);
      let response;

      if (search) {
        // Call searchResource if a search query exists
        response = await searchResource(search);
      } else {
        // Default fetch all resources
        response = await getResources();
      }
      if (response?.data?.code === 200 && response?.data?.status) {
        const filteredResources = response?.data?.data?.filter((resource) =>
          resource.name.toLowerCase().includes(search.toLowerCase())
        );
        const startIndex = page * rowsPerPage;
        const paginatedResources = filteredResources.slice(
          startIndex,
          startIndex + rowsPerPage
        );
        setTotalResources(filteredResources.length);
        setResources(paginatedResources);
      } else {
        toast.error("Failed to fetch resources.");
      }
    } catch (error) {
      console.error("Error fetching resources:", error);
      toast.error("Error fetching resources.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenDialog = (data = null, isView = false) => {
    setDialogData(
      data
        ? {
            ...data,
            createdAt: undefined, // Remove createdAt
            updatedAt: undefined, // Remove updatedAt
            joiningDate: data.joiningDate ? new Date(data.joiningDate) : null, // Set joining date if available
            priorExperience: data.priorExperience ?? "", // Default to empty string
            skills: Array.isArray(data.skills)
              ? data.skills
              : [{ skill: "", relevantExperience: "" }],
          }
        : {
            name: "",
            basePriceMonth: "",
            basePriceWeek: "",
            basePriceDay: "",
            basePriceHour: "",
            status: true,
            joiningDate: null,
            priorExperience: "",
            skills: [{ skill: "", relevantExperience: "" }],
          }
    );
    setViewOnly(isView);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setViewOnly(false);
    setDialogData({
      name: "",
      basePriceMonth: "",
      basePriceWeek: "",
      basePriceDay: "",
      basePriceHour: "",
      status: true,
      joiningDate: null,
      priorExperience: "",
      skills: [],
    });
  };
  console.log("dialogData", dialogData);
  //handle skill
  const handleSkillChange = (index, field, value) => {
    setDialogData((prev) => {
      // Ensure skills array exists
      const updatedSkills = prev.skills ? [...prev.skills] : [];
      console.log("updatedSkills", updatedSkills);
      // Ensure index exists in the array
      if (!updatedSkills[index]) {
        updatedSkills[index] = { skill: "", relevantExperience: "" };
      }
      updatedSkills[index] = { ...updatedSkills[index], [field]: value };
      return { ...prev, skills: updatedSkills };
    });
  };

  const handleRelevantExperienceChange = (index, value) => {
    if (/^\d*$/.test(value) || value === "") {
      // Allow only numbers
      setDialogData((prev) => {
        const updatedSkills = [...prev.skills];
        updatedSkills[index] = {
          ...updatedSkills[index],
          relevantExperience: value,
        };
        return { ...prev, skills: updatedSkills };
      });
    }
  };
  const addSkill = () => {
    setDialogData((prev) => ({
      ...prev,
      skills: [...prev.skills, { skill: "", relevantExperience: "" }], // Add an empty skill entry
    }));
  };

  const handleRemoveSkill = (index) => {
    setDialogData((prev) => {
      const updatedSkills = prev.skills.filter((_, i) => i !== index);

      return {
        ...prev,
        skills:
          updatedSkills.length > 0
            ? updatedSkills
            : [{ skill: "", relevantExperience: "" }],
      };
    });
  };

  const handleSave = async () => {
    // Validate name field
    if (!dialogData.name || dialogData.name.trim() === "") {
      toast.error("Name cannot be empty.");
      return;
    }

    // Validate joiningDate field
    if (!dialogData.joiningDate) {
      toast.error("Joining date cannot be empty.");
      return;
    }

    // Group price fields
    const priceFields = [
      "basePriceMonth",
      "basePriceWeek",
      "basePriceDay",
      "basePriceHour",
    ];

    // Check if any price field is empty
    const emptyPriceFields = priceFields.filter(
      (field) =>
        !dialogData[field] || dialogData[field].toString().trim() === ""
    );
    if (emptyPriceFields.length > 0) {
      toast.error("All price fields must be filled out.");
      return;
    }
    const { basePriceMonth, basePriceWeek, basePriceDay, basePriceHour } =
      dialogData;

    // Validate prices
    if (
      parseFloat(basePriceMonth) <= 0 ||
      parseFloat(basePriceWeek) <= 0 ||
      parseFloat(basePriceDay) <= 0 ||
      parseFloat(basePriceHour) <= 0
    ) {
      toast.error("All prices must be greater than 0.");
      return;
    }

    // Validate priorExperience field (should be a number and at least 0)
    if (
      dialogData.priorExperience === undefined ||
      dialogData.priorExperience === null ||
      dialogData.priorExperience.toString().trim() === "" ||
      isNaN(dialogData.priorExperience) ||
      parseInt(dialogData.priorExperience, 10) < 0
    ) {
      toast.error(
        "Prior experience must be a valid number (0 or more months)."
      );
      return;
    }

    // Calculate total experience
    const joiningExperience = dialogData?.joiningDate
      ? moment().diff(moment(dialogData?.joiningDate), "months")
      : 0;
    const priorExperience = parseInt(dialogData?.priorExperience) || 0;
    const totalExperience = joiningExperience + priorExperience;

    try {
      const {
        _id,
        projects,
        billableProjectCount,
        skills = [],
        ...filteredData
      } = dialogData; // Ensure skills exists

      // Filter skills to include only 'skill' and 'relevantExperience'
      // Ensure skills array is always sent, even if empty
      let hasInvalidSkill = false;
      let hasInvalidExperience = false;
      let hasExcessRelevantExperience = false;

      // Process skills: Remove completely empty ones, and validate non-empty ones
      const filteredSkills = skills
        .map(({ skill, relevantExperience }) => ({
          skill: skill?.trim() || "",
          relevantExperience: relevantExperience?.toString().trim() || "",
        }))
        .filter(({ skill, relevantExperience }) => {
          if (skill && !relevantExperience) {
            hasInvalidExperience = true; // Skill is selected but experience is empty
          }
          if (!skill && relevantExperience) {
            hasInvalidSkill = true; // Experience is provided but skill is missing
          }
          if (
            relevantExperience &&
            parseInt(relevantExperience, 10) > totalExperience
          ) {
            hasExcessRelevantExperience = true;
          }
          return skill || relevantExperience; // Remove only fully empty entries
        });

      // Show errors based on validation
      if (hasInvalidExperience) {
        toast.error("Relevant experience is required for each selected skill.");
        return;
      }
      if (hasInvalidSkill) {
        toast.error(
          "Skill must be selected if relevant experience is provided."
        );
        return;
      }

      if (hasExcessRelevantExperience) {
        toast.error(
          "Relevant experience cannot be greater than total experience."
        );
        return;
      }
      // Ensure at least one valid skill exists
      if (filteredSkills.length === 0) {
        toast.error("At least one valid skill must be provided.");
        return;
      }

      const requestData = { ...filteredData, skills: filteredSkills };
      if (_id) {
        // Update existing resource
        const response = await updateResource(_id, requestData);
        if (response?.data?.code === 200 && response?.data?.status) {
          toast.success("Resource updated successfully!");
        } else {
          toast.error("Failed to update resource.");
        }
      } else {
        // Create new resource
        const response = await createResource(requestData);
        if (response?.data?.code === 200 && response?.data?.status) {
          toast.success("Resource added successfully!");
        } else {
          toast.error("Failed to add resource.");
        }
      }
      handleCloseDialog();
      fetchResources();
    } catch (error) {
      console.error("Error saving resource:", error);
      toast.error("Error saving resource.");
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await deleteResource(id);
      if (response?.data?.code === 200 && response?.data?.status) {
        toast.success("Resource deleted successfully!");
        fetchResources();
        deleteDialogState.toClose();
      } else {
        toast.error("Failed to delete resource.");
      }
    } catch (error) {
      console.error("Error deleting resource:", error);
      toast.error("Error deleting resource.");
    }
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(0);
  };
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleJoiningDateChange = (newDate) => {
    setDialogData((prev) => ({
      ...prev,
      joiningDate: newDate ?? null,
    }));
  };

  return (
    <Box p={3}>
      <Stack direction="row" justifyContent="space-between" mb={2}>
        <TextField
          placeholder="Search resources"
          variant="outlined"
          size="small"
          value={search}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpenDialog()}
        >
          Add Resource
        </Button>
      </Stack>
      {isLoading ? (
        <Box display="flex" justifyContent="center" mt={3}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Card}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Base Price (Month)</TableCell>
                <TableCell>Base Price (Week)</TableCell>
                <TableCell>Base Price (Day)</TableCell>
                <TableCell>Base Price (Hour)</TableCell>
                <TableCell>Total Experience (Months)</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {resources.length > 0 ? (
                resources.map((resource) => {
                  //calculate experience from joiningDate in months
                  const joiningExperience = resource?.joiningDate
                    ? moment().diff(moment(resource?.joiningDate), "months")
                    : 0;

                  //convert priorexperience to integer
                  const priorExperience =
                    parseInt(resource?.priorExperience) || 0;
                  //calculate total experience
                  const totalExperience = joiningExperience + priorExperience;

                  return (
                    <TableRow key={resource._id}>
                      <TableCell>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 10,
                          }}
                        >
                          {resource.billableProjectCount === 1 ? (
                            <Tooltip
                              title="Resource have only one billable project."
                              slotProps={{
                                tooltip: {
                                  sx: {
                                    color: "#514E6A",
                                    backgroundColor: "lightgray",
                                    fontSize: 12,
                                  },
                                },
                              }}
                            >
                              <PersonIcon color="primary" />
                            </Tooltip>
                          ) : resource.billableProjectCount > 1 ? (
                            <Tooltip
                              title="Resource have more than one billable projects."
                              slotProps={{
                                tooltip: {
                                  sx: {
                                    color: "#514E6A",
                                    backgroundColor: "lightgray",
                                    fontSize: 12,
                                  },
                                },
                              }}
                            >
                              <PeopleAltIcon color="primary" />
                            </Tooltip>
                          ) : resource.billableProjectCount === 0 ? (
                            <Tooltip
                              title="Resource have no billable projects."
                              slotProps={{
                                tooltip: {
                                  sx: {
                                    color: "#514E6A",
                                    backgroundColor: "lightgray",
                                    fontSize: 12,
                                  },
                                },
                              }}
                            >
                              <PersonOffIcon color="error" />
                            </Tooltip>
                          ) : null}
                          {resource.name}
                        </div>
                      </TableCell>
                      <TableCell>{resource.basePriceMonth}</TableCell>
                      <TableCell>{resource.basePriceWeek}</TableCell>
                      <TableCell>{resource.basePriceDay}</TableCell>
                      <TableCell>{resource.basePriceHour}</TableCell>
                      <TableCell>{totalExperience}</TableCell>
                      <TableCell
                        sx={{
                          color: resource?.status ? "green" : "crimson",
                          fontWeight: "bold",
                        }}
                      >
                        {resource.status ? "Active" : "Inactive"}
                      </TableCell>
                      <TableCell>
                        <Tooltip title="View">
                          <IconButton
                            color="primary"
                            onClick={() => handleOpenDialog(resource, true)}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit">
                          <IconButton
                            color="primary"
                            onClick={() => handleOpenDialog(resource)}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        {/* <Tooltip title="Delete">
                        <IconButton
                          color="error"
                          onClick={() => deleteDialogState.toOpen(resource._id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip> */}
                        <Tooltip title="Project Involvement">
                          <IconButton
                            color={
                              resource?.projects?.length ? "primary" : "default"
                            }
                            onClick={() => {
                              if (!resource?.projects?.length) {
                                toast(
                                  "This resource is not involved in any project."
                                );
                                return;
                              }
                              projectInvolvementState.toOpen(
                                resource?.projects
                              );
                            }}
                          >
                            <InsightsIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <Paper
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        py: 3,
                        width: "100%",
                      }}
                    >
                      <Typography
                        gutterBottom
                        fontWeight="700"
                        color="#857C7A"
                        variant="subtitle1"
                      >
                        No records of resources.
                      </Typography>
                    </Paper>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalResources}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />

      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {viewOnly
            ? "View Resource"
            : dialogData._id
            ? "Update Resource"
            : "Add Resource"}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Name of the Resource"
                fullWidth
                value={dialogData.name}
                onChange={(e) =>
                  setDialogData({ ...dialogData, name: e.target.value })
                }
                required
                disabled={viewOnly}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Base Price per Month"
                fullWidth
                type="number"
                value={dialogData.basePriceMonth}
                onChange={(e) =>
                  setDialogData({
                    ...dialogData,
                    basePriceMonth: e.target.value,
                  })
                }
                required
                disabled={viewOnly}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Base Price per Week"
                fullWidth
                type="number"
                value={dialogData.basePriceWeek}
                onChange={(e) =>
                  setDialogData({
                    ...dialogData,
                    basePriceWeek: e.target.value,
                  })
                }
                required
                disabled={viewOnly}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Base Price per Day"
                fullWidth
                type="number"
                value={dialogData.basePriceDay}
                onChange={(e) =>
                  setDialogData({ ...dialogData, basePriceDay: e.target.value })
                }
                required
                disabled={viewOnly}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Base Price per Hour"
                fullWidth
                type="number"
                value={dialogData.basePriceHour}
                onChange={(e) =>
                  setDialogData({
                    ...dialogData,
                    basePriceHour: e.target.value,
                  })
                }
                disabled={viewOnly}
              />
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns} required>
                <DesktopDatePicker
                  label="Date of Joining"
                  inputFormat="MM/dd/yyyy"
                  value={dialogData.joiningDate ?? null}
                  onChange={handleJoiningDateChange}
                  renderInput={(params) => (
                    <TextField {...params} required fullWidth />
                  )}
                  disabled={viewOnly}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Prior Experience (Months)"
                fullWidth
                value={dialogData.priorExperience ?? ""}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value) || value === "") {
                    setDialogData({ ...dialogData, priorExperience: value });
                  }
                }}
                required
                disabled={viewOnly}
              />
            </Grid>
            {/* Status */}
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="status-label">Status *</InputLabel>
                <Select
                  labelId="status-label"
                  label="Status"
                  name="status"
                  value={dialogData.status ? "active" : "inactive"}
                  onChange={(e) =>
                    setDialogData({
                      ...dialogData,
                      status: e.target.value === "active",
                    })
                  }
                  disabled={viewOnly}
                >
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="inactive">Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* Skills */}
            <Grid item xs={12}>
              <Grid
                container
                alignItems="center"
                style={{ marginBottom: "10px" }}
              >
                <Grid item xs={12}>
                  <InputLabel
                    style={{ fontWeight: "bold", fontSize: "1.2rem" }}
                  >
                    Skills
                  </InputLabel>
                </Grid>
              </Grid>

              {(dialogData?.skills || []).map((skill, index) => {
                // Get selected skill IDs to exclude them from other dropdowns
                const selectedSkillIds =
                  dialogData.skills?.map((s) => s.skill) || [];
                console.log("selectedSkillIds", dialogData.skills);
                console.log("selectedSkillIds", selectedSkillIds);
                return (
                  <Grid
                    container
                    spacing={2}
                    key={index}
                    alignItems="center"
                    style={{ marginBottom: "10px" }}
                  >
                    {!viewOnly && (
                      <Grid
                        item
                        xs={1}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Tooltip title="Remove Skill">
                          <IconButton
                            color="error"
                            onClick={() => handleRemoveSkill(index)}
                          >
                            <RemoveIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    )}

                    {/* Skill Name Dropdown */}
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel id={`skill-label-${index}`}>
                          {`Skill ${index + 1} *`}
                        </InputLabel>
                        <Select
                          labelId={`skill-label-${index}`}
                          label={`Skill ${index + 1}`}
                          value={skill.skill || ""}
                          onChange={(e) =>
                            handleSkillChange(index, "skill", e.target.value)
                          }
                          disabled={viewOnly}
                          displayEmpty
                          // renderValue={(selected) =>
                          //   selected
                          //     ? approvedSkills.find((s) => s._id === selected)
                          //         ?.name || "Select Skill"
                          //     : "Select Skill"
                          // }
                        >
                          <MenuItem value="" disabled></MenuItem>
                          {approvedSkills
                            .filter(
                              (approvedSkill) =>
                                !selectedSkillIds.includes(approvedSkill._id) ||
                                approvedSkill._id === skill.skill // Keep the selected one
                            )
                            .map((approvedSkill) => (
                              <MenuItem
                                key={approvedSkill._id}
                                value={approvedSkill._id}
                              >
                                {approvedSkill.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    {/* Experience Input */}
                    <Grid item xs={5}>
                      <TextField
                        label="Experience (Months)"
                        name={`relevantExperience-${index}`}
                        value={skill.relevantExperience}
                        onChange={(e) =>
                          handleRelevantExperienceChange(index, e.target.value)
                        }
                        fullWidth
                        required
                        disabled={viewOnly}
                        InputProps={{
                          readOnly: viewOnly,
                          autoComplete: "off",
                          min: 0,
                        }}
                      />
                    </Grid>
                  </Grid>
                );
              })}

              {!viewOnly && (
                <Grid
                  container
                  justifyContent="flex-end"
                  style={{ marginBottom: "9px" }}
                >
                  <Tooltip title="Add Skill" placement="top">
                    <IconButton color="primary" size="large" onClick={addSkill}>
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          {!viewOnly && (
            <Button onClick={handleSave} variant="contained" color="primary">
              {dialogData._id ? "Update" : "Add"}
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={projectInvolvementState.openDialog}
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle>Project Involvement Details</DialogTitle>
        <DialogContent dividers>
          <ProjectInvolvementView
            projectInvolvementStatuses={projectInvolvementState.projectDetails}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={projectInvolvementState.toClose}>
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>

      <DeleteConfirmationDialog
        opened={deleteDialogState.open}
        title={"Resource"}
        handleCancel={() => {
          deleteDialogState.toClose();
        }}
        handleDelete={() => {
          handleDelete(deleteDialogState.delatableDataId);
        }}
      />
    </Box>
  );
}

export default ResourceTab;
