const apis = require("./api.requests");
const path = "/resource/v1";

async function getResources() {
  return await apis.hitGetApi(`${path}/`);
}

async function createResource(body) {
  return await apis.hitPostApi(`${path}/`, body);
}

async function updateResource(resourceId, body) {
  return await apis.hitPutApi(`${path}/${resourceId}`, body);
}

async function deleteResource(resourceId) {
  return await apis.hitDeleteApi(`${path}/${resourceId}`);
}

async function searchResource(searchKey) {
  return await apis.hitGetApi(
    `${path}/?searchKey=${encodeURIComponent(searchKey)}`
  );
}

async function getBenchedResources() {
  return await apis.hitGetApi(`${path}/benched`);
}

export {
  getResources,
  createResource,
  updateResource,
  deleteResource,
  searchResource,
  getBenchedResources,
};
