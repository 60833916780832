import { useNavigate } from "react-router-dom";

// material
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Grid,
  Paper,
  CardContent,
} from "@mui/material";
// components
import Page from "../UI/Page";
// mock
// import { getMyActions } from "../services/common.service";
import * as React from "react";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";

export default function DashboardProjectList(props) {
  const { projectList } = props;
  const navigate = useNavigate();

  const handleClick = (_id) => {
    navigate(`/projects/${_id}`, { replace: true });
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#e3f2fd",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#ede7f6",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <Page title="User">
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Card>
              <CardContent>
                <Typography align="center">{props.head}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            direction="column"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableBody>
                  {projectList.map((row, index) => (
                    <StyledTableRow
                      key={index}
                      onClick={(e, _id) => {
                        // {console.log(e,_id);}
                        handleClick(row._id);
                      }}
                    >
                      <StyledTableCell component="th" scope="row">
                        <strong>TITLES :</strong> {row.title}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        {/* </Card> */}
      </Container>
    </Page>
  );
}
