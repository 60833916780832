const apis = require("./api.requests");

const TAG = "projects.service";
// const contactsPath = require("./projects.service").path;
const path = "/projects/v1";

async function getProjects(page) {
  return await apis.hitPagedApi(`${path}/all`, page);
}

async function getProjectById(projectId) {
  return await apis.hitGetApi(`${path}/${projectId}/details`);
}

// async function updateProject(contactId, leadId, body) {
//   return await apis.hitPutApi(
//     `${contactsPath}${path}/${contactId}/${leadId}`,
//     body
//   );
// }

async function getLeadsForProject(data) {
  return await apis.hitGetApi(`${path}/leads?type=${data}`);
}

async function createComment(projectId, leadId, body) {
  console.log(projectId, leadId, body, "bodyy");
  const { comment, createdBy, cleanedContent } = body;
  let comments = {
    comment: cleanedContent,
    createdBy,
  };
  var formData = new FormData();
  let indexes = [];
  let descriptionFiles = [];
  formData.append("projectId", projectId);

  if (body.files && body.files.length > 0) {
    body.files.map((ele, index) => {
      console.log("ele", ele);
      if (ele.checked) {
        indexes.push(index);
      }
      if (ele.description && ele.description.length) {
        descriptionFiles.push({
          fileIndex: index,
          description: ele.description,
        });
      }
      formData.append("docFile", ele);
    });
  } else {
    formData.append("docFile", []);
    console.log("No files to process");
  }

  formData.append("checkedIndexes", JSON.stringify(indexes));
  formData.append("descriptionFiles", JSON.stringify(descriptionFiles));
  formData.append("comment", JSON.stringify(comments));

  return await apis.hitPostApi(`${path}/${projectId}/comments`, formData);
}

async function getComments(projectId, leadId, page) {
  console.log(projectId, leadId);
  return await apis.hitPagedApi(`${path}/${projectId}/comments`, page);
}

async function createAction(projectId, body) {
  return await apis.hitPostApi(`${path}/${projectId}/actions`, body);
}

async function getActions(projectId, page) {
  return await apis.hitPagedApi(`${path}/${projectId}/actions`, page);
}

async function setProjectAction(projectId, actionId, date) {
  return await apis.hitPostApi(
    `${path}/${projectId}/actions/${actionId}/markAsDone`,
    date
  );
}

async function updateProject(projectId, body) {
  return await apis.hitPutApi(`${path}/${projectId}`, body);
}

async function updateTeam(projectId, body) {
  return await apis.hitPutApi(`${path}/${projectId}/updateTeam`, body);
}

async function createProject(body, flag) {
  if (flag) return await apis.hitPostApiWithFormData(`${path}/`, body);
  else return await apis.hitPostApi(`${path}/`, body);
}

async function addResource(projectId, body) {
  return await apis.hitPostApi(`${path}/${projectId}/addTeam`, body);
}

async function removeResource(projectId, id, _id) {
  return await apis.hitDeleteApi(
    `${path}/${projectId}/${id}/${_id}/removeTeamMember`
  );
}

async function getTechStacks() {
  return await apis.hitGetApi(`/open/techstacks`);
}

// File Upload
async function uploadDocument(projectId, files) {
  var formData = new FormData();
  let indexes = [];
  let descriptionFiles = [];
  formData.append("projectId", projectId);
  files.map((ele, index) => {
    console.log("ele", ele);
    if (ele.checked) {
      indexes.push(index);
    }
    if (ele.description && ele.description.length) {
      descriptionFiles.push({
        fileIndex: index,
        description: ele.description,
      });
    }
    formData.append("docFile", ele);
  });
  formData.append("checkedIndexes", JSON.stringify(indexes));
  formData.append("descriptionFiles", JSON.stringify(descriptionFiles));

  return await apis.hitPostApi(`${path}/${projectId}/uploadDocs`, formData);
}

async function deleteDocument(projectId, data) {
  return await apis.hitDeleteApi(`${path}/${projectId}/${data}/deleteDoc`);
}

async function getProjectSearch(key, value) {
  return await apis.hitGetApi(
    `${path}/search?searchKey=${key}&searchValue=${value}`
  );
}

async function getAllProjectDoersById(projectId) {
  return await apis.hitGetApi(`${path}/${projectId}/getActionDoersByProjectId`);
}

async function getCostEstimationByLeadId(leadId) {
  return await apis.hitGetApi(`/common/v1/costs/${leadId}`);
}
async function updateCostEstimationByLeadId(leadId, data) {
  return await apis.hitPutApi(`/common/v1/costs/${leadId}`, data);
}
async function createCostEstimationByLeadId(data) {
  return await apis.hitPostApi(`/common/v1/costs`, data);
}
async function createInvoice(data) {
  return await apis.hitPostApi(`/common/v1/invoice`, data);
}

async function getInvoiceDetailsByProjectId(projectId) {
  return await apis.hitGetApi(`/common/v1/invoice/${projectId}`);
}

async function cancelInvoice(invoiceId) {
  return await apis.hitPutApi(`/common/v1/cancelInvoice/${invoiceId}`);
}

async function paidInvoice(invoiceId, body) {
  return await apis.hitPutApi(`/common/v1/paymentPaid/${invoiceId}`, body);
}

async function regerateInvoice(invoiceId) {
  return await apis.hitPutApi(`/common/v1/regenerateInvoice/${invoiceId}`);
}
//signed contract
async function uploadSignedContract(projectId, files) {
  var formData = new FormData();
  let indexes = [];
  let descriptionFiles = [];
  formData.append("projectId", projectId);
  files.map((ele, index) => {
    console.log("ele", ele);
    if (ele.checked) {
      indexes.push(index);
    }
    if (ele.description && ele.description.length) {
      descriptionFiles.push({
        fileIndex: index,
        description: ele.description,
      });
    }
    formData.append("docFile", ele);
  });
  formData.append("checkedIndexes", JSON.stringify(indexes));
  formData.append("descriptionFiles", JSON.stringify(descriptionFiles));

  return await apis.hitPostApi(
    `${path}/${projectId}/uploadSignedContract`,
    formData
  );
}

async function getCompany() {
  return await apis.hitGetApi(`/accountandcompany/v1/homecompanies`);
}
async function getBanks() {
  return await apis.hitGetApi(`/accountandcompany/v1/accounts`);
}
export {
  getProjects,
  getProjectById,
  getLeadsForProject,
  createAction,
  getActions,
  setProjectAction,
  createComment,
  getComments,
  createProject,
  uploadDocument,
  deleteDocument,
  updateTeam,
  addResource,
  updateProject,
  removeResource,
  getTechStacks,
  getProjectSearch,
  getAllProjectDoersById,
  getCostEstimationByLeadId,
  updateCostEstimationByLeadId,
  createCostEstimationByLeadId,
  getInvoiceDetailsByProjectId,
  uploadSignedContract,
  createInvoice,
  cancelInvoice,
  paidInvoice,
  regerateInvoice,
  getBanks,
  getCompany,
};
