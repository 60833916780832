// ---------------
import {
  Box,
  Stack,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  InputLabel,
  Select,
  MenuItem,
  DialogContentText,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  LinearProgress,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import React, { useContext, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DescriptionIcon from "@mui/icons-material/Description";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DownloadIcon from "@mui/icons-material/Download";
import InfoIcon from "@mui/icons-material/Info";
import EventBus from "../../components/texteditor/EventBus";

import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../../context/auth.context";
import ProjectInfo from "../../components/Projects/ProjectInfo";
import FileUpload from "../../components/Fileupload";
import FileList from "../../components/FileList";
// import AddIcon from "@mui/icons-material/Add";
import CommentSection from "../../components/Shared/Comments/CommentSection";
import CreateComment from "../../components/Shared/Comments/Comment.Create";
import {
  createProject,
  getAllProjectDoersById,
  getCostEstimationByLeadId,
  updateCostEstimationByLeadId,
  getProjectById,
  updateProject,
  createCostEstimationByLeadId,
  getInvoiceDetailsByProjectId,
  uploadSignedContract,
  cancelInvoice,
  paidInvoice,
  regerateInvoice,
} from "../../services/projects.service";
import { forEach, result } from "lodash";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
// import AddIcon from "@mui/icons-material/Add";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";

// import React, { useContext, useEffect, useState } from "react";
// import LeadCreateComponent from "../components/lead/lead.create";
import LeadRow from "../../components/Lead/Lead.row";

// import AddIcon from "@mui/icons-material/Add";
import { getContactById } from "../../services/contacts.service";
// import EventBus from "../components/texteditor/EventBus";
// import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
// import { AuthContext } from "../context/auth.context";
import { createLead } from "../../services/leads.service";
import { uploadDocument } from "../../services/projects.service";
// import Info from "../components/contacts/Info";
import ActionSection from "../../components/Shared/Action/ActionSection";
import constants from "../../constants";
// import CommentSection from "../components/commentSection";
// import FileUpload from "../components/Fileupload/FileUpload/FileUpload";
// import FileList from "../components/FileList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import TeamSection from "../../components/Teams/TeamSection";
import CircularProgress from "@mui/material/CircularProgress";
import { useDynamicProjectTitle } from "./Projects";
import { useState } from "react";
import CostEstimationView from "../../components/Projects/CostEstimation/CostEstimationView";
import DecidingFactor from "../../components/Dialogs/CostAssignmentDialog/DecidingFactor";
import CostDistributingListing from "../../components/Dialogs/CostAssignmentDialog/CostDistributingListing";
import InvoiceManageTable from "../../components/Projects/CostEstimation/InvoiceManageTable";
import { capitalizedString } from "../../utils/capitalizedString";
import { download } from "../../services/common.service";
import { getGst } from "../../services/attributes.service";
import { getCompanyDetailsByContactId } from "../../services/companies.service";
// import ProjectInfo from "../components/ProjectInfo";

export default function ProjectView() {
  const { projectId } = useParams();
  console.log("id", projectId);
  const projectDynamicTitel = useDynamicProjectTitle();
  // const ProjectCreate = () => {
  const { currentUser, isAuthenticated } = useContext(AuthContext);
  // let initialLeadState = {
  //   summary: "",
  //   skills: "",
  //   techOwner: "",
  //   description: "",
  //   files: [],
  // };
  //TODO: Remove the curly braces
  // const [lead, setLead] = React.useState(initialLeadState);

  // let initialActionState = {
  //   toDo: "",
  //   doer: "",
  //   assignedBy: currentUser._id,
  //   dueDate: new Date(),
  //   // dueTime: new Date(),
  // };
  // const [action, setAction] = React.useState(initialActionState);

  // const initialContactState = {
  //   summary: "",
  //   _id: "",
  //   contactId: "",
  //   contact_name: "",
  // };

  // const [contact, setContact] = React.useState(initialContactState);
  // const [files, setFiles] = React.useState([]);

  // const initialCommentState = {
  //   comment: "",
  //   createdBy: "",
  // };

  // const [comment, setComment] = React.useState(initialCommentState);
  const [isViewDocuments, setIsViewDocuments] = useState(false);
  const [customAttributes, setCustomAttributes] = useState([]);
  const [resource, setResource] = React.useState([]);

  const [isProjectFetched, setIsProjectFetched] = React.useState(false);

  const [isProjectEditPage] = React.useState(true);

  const [isEditPage] = React.useState(true);

  // const [TempContact, setTempContact] = React.useState(initialContactState);

  const [loadedComments, setLoadedComments] = React.useState([]);

  const [loadedActions, setLoadedActions] = React.useState([]);

  // const [contactInfoObj, setContactInfoObj] = React.useState({});

  // const [contact, setContact] = React.useState(initialContactState);

  const [toEdit, setToEdit] = React.useState(true);
  const [descriptionObj, setDescriptionObj] = React.useState({});

  const initialActionState = {
    toDo: "",
    doer: "",
    assignedBy: currentUser._id,
    dueDate: new Date(),
    meetLink: "",
  };
  console.log(initialActionState.dueDate);
  const [action, setAction] = React.useState(initialActionState);

  const initialCommentState = {
    comment: "",
    createdBy: "",
  };

  const [isLeadFetched, setIsLeadFetched] = React.useState(false);
  const [iactionedApiCalled, setIactionedApiCalled] = useState(false);
  const [comment, setComment] = React.useState(initialCommentState);
  const [checked, setChecked] = React.useState(false);

  const navigate = useNavigate();
  const { state } = useLocation();

  const [open, setOpen] = React.useState(false);
  const [docOpen, setDocOpen] = React.useState(false);

  const [files, setFiles] = React.useState([]);
  const [fromCommentCreate, setFromCommentCreate] = React.useState(false);

  const [signedContractFiles, setSignedContractFiles] = React.useState([]);

  const [status, setStatus] = React.useState("");

  const statuses = ["Waiting to start", "Started", "Paused", "Closed"];
  const [isEditIcon, setIsEditIcon] = React.useState(false);
  const [initialProjectState, setInitialProjectState] = React.useState({
    contactId: "",
    leadId: "",
    techOwner: "",
    title: "",
    details: "",
    timeline: {
      noOfRes: 0,
      committedOn: new Date(),
      deadline: new Date(),
    },
    techStack: [],
    team: [],
    specification: "",
    documents: [],
    feedback: {
      rating: 0,
      details: "",
      isSatisfied: false,
      failures: [],
      learnings: [],
    },
    action: {
      toDo: "",
      doer: "",
      assignedBy: currentUser._id,
      dueDate: new Date(),
    },
    contact: {
      name: "",
      company: "",
    },
    status: {
      name: "",
      signedContractUrl: "",
      statusDate: "",
    },
    attributes: [],
  });

  const [project, setProject] = React.useState(initialProjectState);
  const [tempProject, setTempProject] = React.useState(initialProjectState);
  const initialProjectLead = {
    title: "",
    details: "",
    techStack: [],
    specification: "",
  };
  const [tempProjectLead, setTempProjectLead] =
    React.useState(initialProjectLead);
  const [projectLead, setProjectLead] = React.useState(initialProjectLead);
  const [tempProjectTeam, setTempProjectTeam] = React.useState([]);
  let tarf = [];
  const initialTeamSingle = {
    _id: "",
    resourceId: "",
    role: "",
    paymentCycle: "",
  };

  const [teamSingle, setTeamSingle] = React.useState(initialTeamSingle);

  const [editStatus, setEditStatus] = React.useState(false);
  const [saveButton, setSaveButton] = React.useState(false);
  const [loaderDialog, setLoaderDialog] = React.useState(false);
  const [allDoers, setAllDoers] = React.useState([]);
  const [costLoader, setCostLoader] = useState(true);

  // const costModes = ["Monthly", "Daily", "Hourly", "Fixed"];
  const [openProjectStatusDialouge, setOpenProjectStatusDialouge] =
    useState(false);
  const [
    openProjectSignedContarctDialouge,
    setOpenProjectSignedContarctDialouge,
  ] = useState(false);
  // const costModes = ["Time & Material", "Fixed"];
  const costModes = [
    { display: "Time & Material", value: "time & material" },
    { display: "Fixed", value: "fixed" },
  ];
  const [homeCompany, setHomeCompany] = React.useState("");
  const [homeAccount, setHomeAccount] = React.useState("");

  const [costEstimationState, setCostEstimationState] = useState({
    show: false,
    onShow: () => {
      setCostEstimationState((prev) => ({ ...prev, show: true }));
    },
    onHide: (leadId) => {
      setCostEstimationState((prev) => ({ ...prev, show: false }));
    },
    mode: "",
    currency: "",
    gst: null,
    account: "",
    company: "",
    bank: "",
    companyType: "",
    modeDataContainer: {},
  });
  const [gstValues, setGstValues] = useState(null);
  const [parentCompanyDetails, setParentCompanyDetails] = useState({
    companyCountry: "",
    companyState: "",
    companyGstNumber: "",
    companyName: "",
  });
  // const initialTempProjectTeam = [];
  // const [tempProjectTeam, setTempProjectTeam] = React.useState(
  //   initialTempProjectTeam
  // );

  // const [toEdit, setToEdit] = React.useState(false);

  // const [open, setOpen] = React.useState(false);
  const [costTempState, setCostTempState] = useState({
    open: true,
    onClose: () => {
      setCostTempState((prev) => ({
        ...prev,
        open: false,
        mode: "",
        totalCost: "",
        gstIncluded: false,
        numberofResources: "",
        account: "",
        company: "",
        bank: "",
        companyType: "",
        currency: "",
        revenues: [],
        tmRevenues: [],
      }));
    },
    mode: "",
    totalCost: "",
    gstApplicable: false,

    gstIncluded: false,
    account: "",
    company: "",
    bank: "",
    companyType: "",
    numberofResources: "",
    currency: "",
    revenues: [],
    tmRevenues: [],
    handleRevinew: (times) => {
      const data = [];
      for (let index = 0; index < times; index++) {
        data.push({
          paymentCycle: "",
          resourceName: "",
          cost: "",
          invoiceDescription: "",
        });
      }
      setCostTempState((prev) => ({ ...prev, tmRevenues: [...data] }));
    },
    onModesChange: () => {
      setCostTempState((prev) => ({
        ...prev,
        totalCost: "",
        bank: "",
        companyType: "",

        numberofResources: "",
        currency: "",
        revenues: [],
        tmRevenues: [],
      }));
    },
    insertRevenueOneByOne: () => {
      setCostTempState((prev) => {
        const temp = { ...prev };
        temp.revenues.push({
          milestoneName: "",
          cost: "",
          invoiceDescription: "",
        });
        return temp;
      });
    },
    removeRevenues: (allRevenues, index) => {
      const tempRevenues = [...allRevenues];
      tempRevenues.splice(index, 1);
      setCostTempState((prev) => ({
        ...prev,
        revenues: [...tempRevenues],
      }));
    },
  });

  const [invoiceDetailsAndManage, setInvoiceDetailsAndManage] = useState({
    loaderEnable: true,
    data: [],
    cancelInvoice: async (invoiceId, index, leadId) => {
      try {
        const response = await cancelInvoice(invoiceId);
        if (response.data.status) {
          toast("Invoice Canceled Successfully");
          getInvoiceDetails();
          fetchCostEstimation(leadId);
        }
      } catch (error) {
        console.log(error);
      }
    },
    payInvoice: async (invoiceId) => {
      try {
        const response = await paidInvoice(invoiceId);
        if (response.data.status) {
          toast("Invoice Paid Successfully");
          getInvoiceDetails();
        }
      } catch (error) {
        console.log(toast("Something Went Wrong"));
      }
    },
    regenerateInvoice: async (url, invoiceId) => {
      try {
        const response = await regerateInvoice(invoiceId);
        if (response.data.status) {
          window.open(url);
          getInvoiceDetails();
        }
      } catch (error) {
        console.log("Invoice can't generate at this moment");
      }
    },
    invoiceLogs: [],
    openLogsDialog: false,
    handleOpenLogsDialog: () => {
      setInvoiceDetailsAndManage((prev) => ({ ...prev, openLogsDialog: true }));
    },
    handleCloseLogsDialog: () => {
      setInvoiceDetailsAndManage((prev) => ({
        ...prev,
        openLogsDialog: false,
        invoiceLogs: [],
      }));
      setHandleInvoiceLogPagination((prev) => ({
        ...prev,
        rowsPerPage: 5,
        page: 0,
      }));
    },
    storeLogs: (logs) => {
      setInvoiceDetailsAndManage((prev) => ({
        ...prev,
        invoiceLogs: [...logs],
      }));
    },
  });

  const [handleInvoiceLogPagination, setHandleInvoiceLogPagination] = useState({
    rowsPerPage: 5,
    page: 0,
    handleChangePage: (event, newPage) => {
      setHandleInvoiceLogPagination((prev) => ({ ...prev, page: +newPage }));
    },
  });
  const [costAssignmentState, setCostAssignmentState] = useState({
    open: false,
    onClose: () => {
      setCostAssignmentState((prev) => ({
        ...prev,
        open: false,
        mode: "",
        totalCost: "",
        account: "",
        company: "",
        bank: "",
        companyType: "",
        gstIncluded: false,
        numberofResources: "",
        currency: "",
        revenues: [],
        tmRevenues: [],
      }));
    },
    mode: "",
    totalCost: "",
    account: "",
    company: "",
    bank: "",
    companyType: "",
    gstApplicable: false,
    gstIncluded: false,
    numberofResources: "",
    currency: "",
    revenues: [],
    tmRevenues: [],
    handleRevinew: (times) => {
      const data = [];
      for (let index = 0; index < times; index++) {
        data.push({
          paymentCycle: "",
          resourceName: "",
          cost: "",
          invoiceDescription: "",
        });
      }
      setCostAssignmentState((prev) => ({ ...prev, tmRevenues: [...data] }));
    },
    onModesChange: () => {
      setCostAssignmentState((prev) => ({
        ...prev,
        totalCost: "",
        gstIncluded: false,
        numberofResources: "",
        account: "",
        company: "",
        bank: "",
        companyType: "",
        currency: "",
        revenues: [],
        tmRevenues: [],
      }));
    },
    insertRevenueOneByOne: () => {
      setCostAssignmentState((prev) => {
        if (prev.revenues.length < 20) {
          const temp = { ...prev };
          temp.revenues.push({
            milestoneName: "",
            cost: "",
            invoiceDescription: "",
          });

          return temp;
        } else {
          toast.warn("You cannot add more than 20 items.");
          return prev;
        }
      });
    },
    insertTmRevenueOneByOne: () => {
      setCostAssignmentState((prev) => {
        // const temp = { ...prev };

        if (prev.tmRevenues.length < 20) {
          const temp = { ...prev };
          temp.tmRevenues.push({
            paymentCycle: "",
            resourceName: "",
            cost: "",
            invoiceDescription: "",
          });
          temp.numberofResources = parseInt(prev.numberofResources) + 1;
          return temp;
        } else {
          toast.warn("You cannot add more than 20 items.");
          return prev;
        }
      });
    },
    removeRevenues: (allRevenues, index) => {
      const tempRevenues = [...allRevenues];
      tempRevenues.splice(index, 1);
      setCostAssignmentState((prev) => ({
        ...prev,
        revenues: [...tempRevenues],
      }));
    },
    removeTmRevenues: (allRevenues, index) => {
      if (allRevenues.length === 1) {
        toast.warning("The last resource can not be removed.");
        return;
      }
      const tempRevenues = [...allRevenues];
      tempRevenues.splice(index, 1);
      setCostAssignmentState((prev) => ({
        ...prev,
        tmRevenues: [...tempRevenues],
        numberofResources: prev.numberofResources - 1,
      }));
    },
    handleIncludeGst: (event) => {
      setCostAssignmentState((prev) => ({
        ...prev,
        gstIncluded: event.target.checked,
      }));
    },
  });

  console.log(homeCompany, homeAccount, "homeDeatils");

  const ResetForm = () => {
    setAction(initialActionState);
    EventBus.dispatch("EditorBus");
    project.timeline.noOfRes = 0;
    project.timeline.committedOn = new Date();
    project.timeline.deadline = new Date();
    setProject(initialProjectState);

    setResource([]);
  };

  const [createContactShowTimer, setCreateContactShowTimer] =
    React.useState(false);
  const notify = () => toast("Successfully contact created!");

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    console.log(date);
    if (!createContactShowTimer) {
      date = new Date(date);
    }
    return (
      [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join("-") +
      " " +
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds()),
      ].join(":")
    );
  }

  const handleClickOpen = (fromViewDoc) => {
    setOpen(true);
    setIsViewDocuments(fromViewDoc);
  };
  const handleDocClickOpen = () => {
    setDocOpen(true);
  };
  console.log(costAssignmentState, "newcost");

  const handleClose = () => {
    setDescriptionObj({});
    setFiles((prev) => {
      return isViewDocuments ? prev : (prev = []);
    });
    setOpen(false);
    setIsViewDocuments(false);
  };
  const handleDocClose = () => {
    setDescriptionObj({});
    setFiles((prev) => {
      return (prev = []);
    });
    setDocOpen(false);
  };

  const handleCloseSignedContract = () => {
    setSignedContractFiles((prev) => {
      return (prev = []);
    });

    setOpenProjectStatusDialouge((prev) => !prev);
  };

  const uploadHandler = (event) => {
    let tempFiles = [];
    [...event.target.files].map((file, i) => {
      file["description"] = "";
      tempFiles.push(file);
    });

    setFiles((prev) => {
      return [...prev, ...tempFiles];
      // return [...prev, ...files];
    });
    setOpen(() => {
      return true;
    });
  };

  const submitFile = async () => {
    if (files.length === 0) {
      toast.warning("File can not be empty");
      return;
    }
    console.log("submit file run");
    Object.keys(descriptionObj).forEach((eleInd) => {
      files[parseInt(eleInd)].description = descriptionObj[parseInt(eleInd)];
    });
    try {
      setSaveButton(true);
      setLoaderDialog(true);
      console.log("files 565", files);
      const response = await uploadDocument(projectId, files);
      console.log("Response file upload", response);
      if (response && response.data.status) {
        setProject((prev) => {
          let temp = prev;
          temp.status.name = "Started";
          return temp;
        });
        setIsProjectFetched(false);
        setDescriptionObj({});
        setIactionedApiCalled(true);
        toast("Documents uploaded sucessfully");
        handleDocClose();
        setSaveButton(false);
        setLoaderDialog(false);
        setOpenProjectStatusDialouge(false);

        // updateStatus();
      }
    } catch (error) {
      console.log(error);
      setDescriptionObj({});
      setSaveButton(false);
      setLoaderDialog(false);
    }
  };
  const submitDocComment = async () => {
    console.log("running");
    // setFromCommentCreate(false);
    setOpen(false);
  };
  console.log(fromCommentCreate, saveButton, "runn");
  const submitUploadSignedContract = async () => {
    try {
      if (signedContractFiles.length === 0) {
        toast.warning("File can not be empty");
        return;
      }
      setSaveButton(true);
      setLoaderDialog(true);
      console.log(files);
      const response = await uploadSignedContract(
        projectId,
        signedContractFiles
      );
      console.log("Response file upload", response);
      if (response && response.data.status) {
        setProject((prev) => {
          let temp = prev;
          temp.status.name = "Started";
          return temp;
        });
        setIsProjectFetched(false);
        setDescriptionObj({});
        setIactionedApiCalled(true);
        toast("Documents uploaded sucessfully");
        toast("Status updated successfully");
        handleClose();
        setSaveButton(false);
        setLoaderDialog(false);
        setOpenProjectStatusDialouge(false);
      }
    } catch (error) {
      console.log(error);
      setDescriptionObj({});
      setSaveButton(false);
      setLoaderDialog(false);
    }
  };

  const getAllDoersById = async () => {
    getAllProjectDoersById(projectId)
      .then((result) => {
        if (result.data.status) {
          setAllDoers(result.data.data[0].actionDoers);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCompanyDetails = async (contactId) => {
    try {
      const response = await getCompanyDetailsByContactId(contactId);
      if (response.data.status) {
        console.log("datawewew", response.data.data);
        const data = response.data.data;
        const { companyCountry, companyState, companyGstNumber, companyName } =
          data;
        setParentCompanyDetails((prev) => ({
          ...prev,
          companyCountry: companyCountry,
          companyState: companyState,
          companyGstNumber: companyGstNumber,
          companyName: companyName,
        }));
        if (companyCountry?.toLowerCase() === "india") {
          console.log("Hiii");
          setCostAssignmentState((prev) => ({ ...prev, gstApplicable: true }));
        }
      }
    } catch (error) {
      console.log(error, "gst error");
    }
  };

  React.useEffect(() => {
    if (!isProjectFetched) {
      getProjectById(projectId)
        .then((response) => {
          if (response.data.status) {
            getAllDoersById();
            getCompanyDetails(response.data.data[0].contactId);
            fetchCostEstimation(response.data.data[0].leadId);
            setProject(JSON.parse(JSON.stringify(response.data.data[0])));
            setTempProject(JSON.parse(JSON.stringify(response.data.data[0])));
            setCustomAttributes(
              JSON.parse(JSON.stringify(response.data.data[0].attributes))
            );

            const temp = { ...initialProjectLead };
            temp.details = response.data.data[0].details;
            temp.specification = response.data.data[0].specification;
            temp.techStack = response.data.data[0].techStack;
            temp.title = response.data.data[0].title;
            setTempProjectLead(JSON.parse(JSON.stringify(temp)));
            setProjectLead(JSON.parse(JSON.stringify(temp)));
            tarf = response.data.data[0].team;

            setTempProjectTeam((prev) => {
              let temp = [...response.data.data[0].team];
              temp = temp.map((item) => ({
                ...item,
              }));
              return temp;
            });
            setIsProjectFetched((prev) => (prev = true));
            setResource(response.data?.data[0]?.resources || []);

            console.log("project ", project);
          } else {
            navigate(`/unauthorized`);
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.data.code == 401) {
            navigate(`/unauthorized`);
          }
        });
    }
  }, [isProjectFetched, files]);

  console.log(isProjectFetched);

  const handleStatusChange = async (e) => {
    setProject((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const updateStatus = async () => {
    if (project.status !== tempProject.status) {
      try {
        project.status.statusDate = new Date();
        const response = await updateProject(projectId, {
          status: project.status,
          attributes: [...customAttributes],
        });
        if (response.data.status) {
          setEditStatus(false);
          setIactionedApiCalled(true);
          toast("Status updated successfully");
          setIsEditIcon(false);
          setIsProjectFetched(false);
          setIactionedApiCalled(true);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      toast("Nothing to update");
    }
  };

  async function fetchCostEstimation(leadId) {
    try {
      const response = await getCostEstimationByLeadId(leadId);
      if (response.data.status) {
        setCostLoader(false);
        switch (response.data.data.mode.toLowerCase()) {
          case "fixed":
            setCostEstimationState((prev) => ({
              ...prev,
              mode: response.data.data.mode,
              currency: response.data.data.currency,
              gstIncluded: response.data.data.gstIncluded,
              gstApplicable: response.data.data.gstApplicable,
              gst: response.data.data.gst[0],
              homeAccount: response.data.data.bankName.name,
              homeAccountCountry: response.data.data.bankName.country,
              homeAccountState: response.data.data.bankName.state,
              homeCompany: response.data.data.companyTypeName.name,
              homeCompanyCountry: response.data.data.companyTypeName.country,
              homeCompanyState: response.data.data.companyTypeName.state,
              bank: response.data.data.bank,
              companyType: response.data.data.companyType,
              modeDataContainer: { ...response.data.data.fixed },
            }));
            setHomeCompany(response.data.data.companyTypeName.id);
            setHomeAccount(response.data.data.bankName.id);

            setCostAssignmentState((prev) => ({
              ...prev,
              mode: response.data.data.mode,
              gst: response.data.data.gst[0],
              totalCost: response.data.data.fixed.totalCost,
              numberofResources: "",
              currency: response.data.data.currency,
              account: response.data.data.bankName.name,
              company: response.data.data.companyTypeName.name,
              homeCompanyCountry: response.data.data.companyTypeName.country,
              bank: response.data.data.bank,
              companyType: response.data.data.companyType,
              gstIncluded: response.data.data.gstIncluded,
              gstApplicable: response.data.data.gstApplicable,
              revenues: response?.data?.data?.fixed.milestones?.map((elem) => {
                return {
                  cost: elem.cost,
                  invoiceDescription: elem.invoiceDescription,
                  milestoneName: elem.milestoneName,
                  invoiceStatus: elem.invoiceStatus,
                };
              }),
              open: false,
            }));

            setCostTempState((prev) => ({
              ...prev,
              open: false,
              mode: response.data.data.mode,
              gst: response.data.data.gst[0],
              totalCost: response.data.data.fixed.totalCost,
              numberofResources: "",
              currency: response.data.data.currency,
              gstIncluded: response.data.data.gstIncluded,
              gstApplicable: response.data.data.gstApplicable,
              account: response.data.data.bankName.name,
              company: response.data.data.companyTypeName.name,
              homeCompanyCountry: response.data.data.companyTypeName.country,
              bank: response.data.data.bank,
              companyType: response.data.data.companyType,
              revenues: response?.data?.data?.fixed.milestones?.map((elem) => {
                return {
                  cost: elem.cost,
                  invoiceDescription: elem.invoiceDescription,
                  milestoneName: elem.milestoneName,
                  invoiceStatus: elem.invoiceStatus,
                };
              }),
            }));

            break;

          default:
            setHomeCompany(response.data.data.companyTypeName.id);
            setHomeAccount(response.data.data.bankName.id);
            setCostEstimationState((prev) => ({
              ...prev,
              mode: response.data.data.mode,
              gst: response.data.data.gst[0],
              companyType: response.data.data.companyType,
              bank: response.data.data.bank,
              currency: response.data.data.currency,
              homeAccount: response.data.data.bankName.name,
              homeAccountCountry: response.data.data.bankName.country,
              homeAccountState: response.data.data.bankName.state,
              homeCompany: response.data.data.companyTypeName.name,
              homeCompanyCountry: response.data.data.companyTypeName.country,
              homeCompanyState: response.data.data.companyTypeName.state,
              gstIncluded: response.data.data.gstIncluded,
              gstApplicable: response.data.data.gstApplicable,
              modeDataContainer: { ...response.data.data.dynamic },
            }));
            setCostAssignmentState((prev) => ({
              ...prev,
              open: false,
              mode: response.data.data.mode,
              gst: response.data.data.gst[0],
              companyType: response.data.data.companyType,
              bank: response.data.data.bank,
              numberofResources: response?.data?.data?.dynamic?.noOfResources,
              currency: response.data.data.currency,
              gstIncluded: response.data.data.gstIncluded,
              gstApplicable: response.data.data.gstApplicable,
              account: response.data.data.bankName.name,
              company: response.data.data.companyTypeName.name,
              homeCompanyCountry: response.data.data.companyTypeName.country,
              tmRevenues: response?.data?.data?.dynamic.costEstimation?.map(
                (elem) => {
                  return {
                    cost: elem.cost,
                    invoiceDescription: elem.invoiceDescription,
                    paymentCycle: elem.paymentCycle,
                    resourceName: elem.resourceName,
                    invoiceStatus: elem.invoiceStatus,
                  };
                }
              ),
            }));
            setCostTempState((prev) => ({
              ...prev,
              open: false,
              mode: response.data.data.mode,
              gst: response.data.data.gst[0],
              numberofResources: response?.data?.data?.dynamic?.noOfResources,
              currency: response.data.data.currency,
              gstIncluded: response.data.data.gstIncluded,
              gstApplicable: response.data.data.gstApplicable,
              account: response.data.data.bankName.name,
              company: response.data.data.companyTypeName.name,
              homeCompanyCountry: response.data.data.companyTypeName.country,
              bank: response.data.data.bank,
              companyType: response.data.data.companyType,
              tmRevenues: response?.data?.data?.dynamic.costEstimation?.map(
                (elem) => {
                  return {
                    cost: elem.cost,
                    invoiceDescription: elem.invoiceDescription,
                    paymentCycle: elem.paymentCycle,
                    resourceName: elem.resourceName,
                    invoiceStatus: elem.invoiceStatus,
                  };
                }
              ),
            }));
            break;
        }
      } else {
        setCostLoader(false);
      }
    } catch (error) {
      toast("Price Estimation Not Found");
    }
  }

  const handleCostAssignmentDialogState = (event, companyDetails) => {
    if (event.target.value && !event.target.value?.trim()) {
      toast.warn("Invoice description can not contain only space");
      return;
    }

    if (event.target.name === "totalCost" && event.target.value.length > 14) {
      toast.warn("Cost should not cross 14 digit");
      return;
    }
    if (event.target.name === "company") {
      setCostAssignmentState((prev) => ({
        ...prev,
        [event.target.name]: event.target.value,
      }));
      const homeCompanyFound = companyDetails.find(
        (elem) => event.target.value === elem.name
      );
      if (homeCompanyFound) {
        setHomeCompany(homeCompanyFound.id);
        if (
          parentCompanyDetails.companyCountry?.toLowerCase() === "india" &&
          homeCompanyFound.country?.toLowerCase() === "india"
        ) {
          setCostAssignmentState((prev) => ({ ...prev, gstApplicable: true }));
          return;
        }
        if (
          !parentCompanyDetails.companyCountry &&
          homeCompanyFound.country?.toLowerCase() === "india" &&
          costAssignmentState.currency === "INR (₹)"
        ) {
          setCostAssignmentState((prev) => ({ ...prev, gstApplicable: true }));
          return;
        }
        setCostAssignmentState((prev) => ({ ...prev, gstApplicable: false }));
      }
    }
    if (event.target.name === "account") {
      console.log(event.target.value, event.target.name, "event value");

      // const { id, name, country } = event.target.value;
      setCostAssignmentState((prev) => ({
        ...prev,
        [event.target.name]: event.target.value,
      }));
      companyDetails.find((elem, i) => {
        if (event.target.value === elem.name) {
          setHomeAccount(elem.id);
          return elem;
        }
        // return elem;
      });
    }
    if (event.target.name === "numberofResources") {
      if (event.target.value > 20) {
        toast.warn("Number of resource should not be greate than 20");
        return;
      }

      costAssignmentState.handleRevinew(parseInt(event.target.value));
      setCostAssignmentState((prev) => ({
        ...prev,
        [event.target.name]: event.target.value,
      }));
    }
    if (event.target.name === "currency") {
      setCostAssignmentState((prev) => ({
        ...prev,
        [event.target.name]: event.target.value,
      }));
      if (
        !parentCompanyDetails?.companyCountry &&
        event.target.value === "INR (₹)"
      ) {
        setCostAssignmentState((prev) => ({
          ...prev,
          gstApplicable: true,
        }));
      }
    } else {
      setCostAssignmentState((prev) => ({
        ...prev,
        [event.target.name]: event.target.value,
      }));
    }
  };

  const handleCostAndInvoiceDescription = (event, index, fieldName, mode) => {
    // let regex = /^[a-zA-Z ]*$/;
    let regex = /^[a-zA-Z0-9 ]*$/;
    switch (fieldName) {
      case "paymentCycle":
        if (event.target.value && !event.target.value?.trim()) {
          toast.warn("Payment cycle can not contain only space");
          return;
        }
        setCostAssignmentState((prev) => {
          const temp = { ...prev };
          temp.tmRevenues[index][fieldName] = event.target.value;
          return temp;
        });
        break;
      case "resourceName":
        if (event.target.value && !event.target.value?.trim()) {
          toast.warn("Resource name can not contain only space");
          return;
        }
        const Rvalue = regex.test(event.target.value);
        if (!Rvalue) {
          toast.warn("Special characters are not allowed");
          return;
        }

        // if (/\d/.test(event.target.value)) {
        //   toast.warn("Resource name cannot contain numbers");
        //   return;
        // }
        setCostAssignmentState((prev) => {
          const temp = { ...prev };
          temp.tmRevenues[index][fieldName] = event.target.value;
          return temp;
        });
        break;
      case "milestoneName":
        if (event.target.value && !event.target.value?.trim()) {
          toast.warn("Milestone name can not contain only space");
          return;
        }

        const Mvalue = regex.test(event.target.value);

        if (!Mvalue) {
          toast.warn("Special characters are not allowed");
          return;
        }
        // if (/\d/.test(event.target.value)) {
        //   toast.warn("Milestone cannot contain numbers");
        //   return;
        // }
        setCostAssignmentState((prev) => {
          const temp = { ...prev };
          temp.revenues[index][fieldName] = event.target.value;
          return temp;
        });
        break;
      case "cost":
        if (event.target.value && !event.target.value?.trim()) {
          toast.warn("Cost can not contain only space");
          return;
        }
        if (mode === "fixed") {
          setCostAssignmentState((prev) => {
            const temp = { ...prev };
            temp.revenues[index][fieldName] = event.target.value;
            return temp;
          });
        } else {
          setCostAssignmentState((prev) => {
            const temp = { ...prev };
            temp.tmRevenues[index][fieldName] = event.target.value;
            return temp;
          });
        }

        // setCostAssignmentState((prev) => {
        //   const temp = { ...prev };
        //   temp.revenues[index][fieldName] = event.target.value;
        //   return temp;
        // });
        break;

      case "invoiceDescription":
        if (event.target.value && !event.target.value?.trim()) {
          toast.warn("Invoice description can not contain only space");
          return;
        }
        if (mode === "fixed") {
          setCostAssignmentState((prev) => {
            const temp = { ...prev };
            temp.revenues[index][fieldName] = event.target.value;
            return temp;
          });
        } else {
          setCostAssignmentState((prev) => {
            const temp = { ...prev };
            temp.tmRevenues[index][fieldName] = event.target.value;
            return temp;
          });
        }
        // setCostAssignmentState((prev) => {
        //   const temp = { ...prev };
        //   temp.revenues[index][fieldName] = event.target.value;
        //   return temp;
        // });

        break;

      default:
        break;
    }
  };

  const handleReadonlyMileStone = () => {
    const sumOfCosts = costAssignmentState.revenues.reduce(
      (accumulator, ele) => {
        return (accumulator += parseInt(ele.cost));
      },
      0
    );
    if (sumOfCosts >= parseInt(costAssignmentState.totalCost)) return true;
    return false;
  };

  const checkCountryCurrency = (companyDetails) => {
    if (
      companyDetails?.companyCountry === "India" ||
      (costAssignmentState.currency === "INR (₹)" &&
        !companyDetails?.companyCountry)
    ) {
      return true;
    }
    return false;
  };

  const provideProposalBudget = (mode) => {
    switch (mode) {
      case "fixed":
        return {
          mode: mode,
          currency: costAssignmentState.currency,
          // gstIncluded:costAssignmentState.currency !== "INR (₹)" //TODO :: Condition Change
          //     ? false
          //     : costAssignmentState.gstIncluded,
          // gstIncluded: checkCountryCurrency(companyDetails),
          gstIncluded: costAssignmentState.gstIncluded,
          leadId: project.leadId,
          companyType: homeCompany,
          bank: homeAccount,
          gstApplicable: costAssignmentState.gstApplicable,
          fixed: {
            active: true,
            totalCost: parseInt(costAssignmentState.totalCost),
            milestones: costAssignmentState.revenues,
            noOfMilestones: costAssignmentState.revenues.length,
          },
        };
      default:
        return {
          mode: mode,
          currency: costAssignmentState.currency,
          // gstIncluded: checkCountryCurrency(companyDetails),
          gstIncluded: costAssignmentState.gstIncluded,

          leadId: project.leadId,
          companyType: homeCompany,
          bank: homeAccount,
          gstApplicable: costAssignmentState.gstApplicable,

          dynamic: {
            active: true,
            noOfResources: costAssignmentState.numberofResources,
            costEstimation: costAssignmentState.tmRevenues,
          },
        };
    }
  };

  const onModeChangeHandel = (mode) => {
    if (costTempState.mode !== mode) {
      if (mode === "fixed") {
        setCostAssignmentState((prev) => ({
          ...prev,
          numberofResources: "",
          currency: "",
          account: "",
          company: "",
          gstIncluded: false,
          tmRevenues: [],
        }));
      } else {
        setCostAssignmentState((prev) => ({
          ...prev,
          totalCost: "",
          gstIncluded: false,
          currency: "",
          account: "",
          company: "",
          revenues: [],
        }));
      }
    } else {
      setCostAssignmentState({ ...costTempState, open: true });
    }
  };

  const today = new Date();
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  const convertstrDtae = new Date(project.status.statusDate);
  const formattedDateTime = convertstrDtae.toLocaleDateString("en-GB", options);
  console.log("formattedDateTime", formattedDateTime);

  const handleAdd = async () => {
    const proposalBudgetData = provideProposalBudget(costAssignmentState.mode);
    console.log(proposalBudgetData, "budget data");

    if (proposalBudgetData) {
      updateCostEstimationByLeadId(project.leadId, proposalBudgetData)
        .then((response) => {
          if (response.data.status) {
            console.log("Hi");
            fetchCostEstimation(project.leadId);
            toast(response.data.message);
            costAssignmentState.onClose();
            costTempState.onClose();

            // return;
          } else {
            navigate(`/unauthorized`);
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.data.code == 401) {
            navigate(`/unauthorized`);
          }
          if (error.response.data.code == 400) {
            console.log(`UpdateCostEstimation.error: `, error);
            toast(error.response.data.message);
            return;
          }
          // toast(error.response.data.message);
        });
    } else {
      return;
    }
  };

  const handleCreate = async () => {
    const proposalBudgetData = provideProposalBudget(costAssignmentState.mode);

    if (proposalBudgetData) {
      createCostEstimationByLeadId(proposalBudgetData)
        .then((response) => {
          if (response.data.status) {
            fetchCostEstimation(project.leadId);
            toast(response.data.message);
            costAssignmentState.onClose();
            costTempState.onClose();
            // return;
          } else {
            navigate(`/unauthorized`);
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.data.code == 401) {
            navigate(`/unauthorized`);
          }
          if (error.response.data.code == 400) {
            console.log(`UpdateCostEstimation.error: `, error);
            toast(error.response.data.message);
            return;
          }
          // toast(error.response.data.message);
        });
    } else {
      return;
    }

    console.log("hello");
  };
  const requirementsRevenuesIsEmpty =
    costAssignmentState.mode === "fixed"
      ? costAssignmentState?.revenues?.filter(
          (item) =>
            // item?.cost &&
            item.invoiceDescription?.trim() && item.milestoneName?.trim()
        ).length === costAssignmentState.revenues.length
      : costAssignmentState?.tmRevenues?.filter(
          (item) =>
            // item?.cost &&
            item.invoiceDescription?.trim() && item.resourceName?.trim()
        ).length === costAssignmentState.tmRevenues.length;

  const checkTotalCostIsEqualtoRevenueCost =
    +costAssignmentState.totalCost ===
    costAssignmentState.revenues.reduce(
      (prev, curr) => (prev += parseInt(curr.cost)),
      0
    );

  const checkIsEmpty = (mode) => {
    console.log("modekoi", mode);
    if (mode !== null && mode !== undefined) {
      switch (mode) {
        case "fixed": {
          console.log("modekoi0", checkTotalCostIsEqualtoRevenueCost);
          const isFixedValid =
            !checkTotalCostIsEqualtoRevenueCost ||
            !requirementsRevenuesIsEmpty ||
            !costAssignmentState.currency ||
            !costAssignmentState.company ||
            !costAssignmentState.account ||
            parseInt(costAssignmentState.totalCost) === 0;

          console.log(isFixedValid, "fixed valid");
          return isFixedValid;
        }
        default:
          const isDynamicValide =
            !costAssignmentState.mode ||
            !costAssignmentState.currency ||
            !costAssignmentState.company ||
            !costAssignmentState.account ||
            // !costAssignmentState.revenues.length ||
            // !costAssignmentState.tmRevenues.length ||
            !requirementsRevenuesIsEmpty;
          console.log(isDynamicValide, "dynamic valid");
          return isDynamicValide;
      }
    }
    return true;
  };

  const checkCostEmptyOrNot = (costEstimate) => {
    if (costEstimate.mode !== null && costEstimate.mode !== undefined) {
      switch (costEstimate.mode) {
        case "fixed": {
          const isCostEmpty = costEstimate.modeDataContainer.noOfMilestones
            ? true
            : false;

          return isCostEmpty;
        }
        default:
          const isCostEmpty = costEstimate.modeDataContainer.noOfResources
            ? true
            : false;
          return isCostEmpty;
      }
    }
    return true;
  };

  async function getInvoiceDetails() {
    try {
      const response = await getInvoiceDetailsByProjectId(projectId);
      if (response.data.status) {
        setInvoiceDetailsAndManage((prev) => ({
          ...prev,
          data: [...response.data.data],
          loaderEnable: false,
        }));
      }
    } catch (error) {
      setInvoiceDetailsAndManage((prev) => ({
        ...prev,
        loaderEnable: false,
      }));
    }
  }

  function showLogs(_id) {
    const invoiceDetails = invoiceDetailsAndManage.data.find(
      (inv) => inv._id === _id
    );
    if (!invoiceDetails || !invoiceDetails.activityLog.length) {
      toast("No Logs Found");
      return;
    }
    invoiceDetailsAndManage.storeLogs([...invoiceDetails.activityLog]);
    invoiceDetailsAndManage.handleOpenLogsDialog();
  }

  const getGSTData = async () => {
    try {
      const response = await getGst();
      if (response.data.status) {
        const data = response.data.data;
        setGstValues({ ...data[0] });
      }
    } catch (error) {
      console.log(error, "gst error");
    }
  };

  React.useEffect(() => {
    getInvoiceDetails();
    getGSTData();
  }, []);

  const fileName = project.status.signedContractUrl.split("/").pop();

  function dateChange(date) {
    const dateTimeInSeconds = new Date(date).getTime();

    const offSet = new Date().getTimezoneOffset();

    const offSetInSeconds = offSet * 60 * 1000;

    const newDateInSeconds = dateTimeInSeconds - offSetInSeconds;

    const dateString = [
      padTo2Digits(new Date(newDateInSeconds).getUTCDate()),
      padTo2Digits(new Date(newDateInSeconds).getUTCMonth() + 1),
      new Date(newDateInSeconds).getUTCFullYear(),
    ].join("/");

    return dateString;
  }
  return (
    <>
      <Dialog
        fullWidth
        open={invoiceDetailsAndManage.openLogsDialog}
        onClose={invoiceDetailsAndManage.handleCloseLogsDialog}
      >
        <DialogTitle>Invoice Logs</DialogTitle>
        <DialogContent dividers>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Time</TableCell>
                  <TableCell>Performed By</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoiceDetailsAndManage.invoiceLogs
                  ?.slice(
                    handleInvoiceLogPagination.page *
                      handleInvoiceLogPagination.rowsPerPage,
                    handleInvoiceLogPagination.page *
                      handleInvoiceLogPagination.rowsPerPage +
                      handleInvoiceLogPagination.rowsPerPage
                  )
                  .map((log, index) => {
                    let { status, date, interactedUser, updatedAt, createdAt } =
                      log;
                    return (
                      <TableRow key={index}>
                        <TableCell>{status}</TableCell>
                        <TableCell>{dateChange(updatedAt)}</TableCell>
                        <TableCell>
                          {new Date(updatedAt).toLocaleTimeString()}
                        </TableCell>
                        <TableCell>{interactedUser}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            labelRowsPerPage=""
            rowsPerPageOptions={[5]}
            component="div"
            count={invoiceDetailsAndManage.invoiceLogs.length}
            rowsPerPage={handleInvoiceLogPagination.rowsPerPage}
            page={handleInvoiceLogPagination.page}
            onPageChange={handleInvoiceLogPagination.handleChangePage}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={invoiceDetailsAndManage.handleCloseLogsDialog}>
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
      <Container>
        <ToastContainer />
        <Grid container spacing={4}>
          <Grid item>
            <Card
              sx={{ backgroundColor: "#f1f2f6" }}
              elevation={5}
              variant="outlined"
            >
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6}>
                    <Typography variant="h5" gutterBottom color="primary">
                      {`${projectDynamicTitel?.displayName.singularName.toUpperCase()} DETAILS`}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <ProjectInfo
                      setProject={setProject}
                      project={project}
                      toEdit={toEdit}
                      setToEdit={setToEdit}
                      isProjectEditPage={isProjectEditPage}
                      fromViewPage={true}
                      tempProject={tempProject}
                      setTempProject={setTempProject}
                      setTempProjectLead={setTempProjectLead}
                      tempProjectLead={tempProjectLead}
                      projectLead={projectLead}
                      setProjectLead={setProjectLead}
                      tarf={tarf}
                      projectId={projectId}
                      resource={resource}
                      setResource={setResource}
                      setIsProjectFetched={setIsProjectFetched}
                      customAttributes={customAttributes}
                      setCustomAttributes={setCustomAttributes}
                      setIactionedApiCalled={setIactionedApiCalled}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Dialog
            maxWidth="lg"
            fullWidth
            open={costAssignmentState.open}
            onClose={() => {}}
          >
            <DialogTitle>Proposal Budget</DialogTitle>
            <DialogContent dividers>
              <Grid container>
                <Grid item xs={12}>
                  <div>
                    <>
                      <Grid container spacing={1.2}>
                        <Grid
                          item
                          xs={1}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography>Modes:</Typography>
                        </Grid>
                        <Grid
                          item
                          xs={10}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <FormControl fullWidth>
                            {/* <FormLabel>Modes</FormLabel> */}
                            <RadioGroup
                              row
                              name="mode"
                              value={costAssignmentState.mode}
                              onChange={(event) => {
                                // costAssignmentState.onModesChange();
                                onModeChangeHandel(event.target.value);
                                handleCostAssignmentDialogState(event);
                              }}
                            >
                              {costModes.map((ele, index) => (
                                <FormControlLabel
                                  key={index}
                                  value={ele.value}
                                  control={<Radio />}
                                  label={ele.display}
                                />
                              ))}
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        <Grid item mt={1} xs={12}>
                          {costAssignmentState.mode ? (
                            costAssignmentState.mode === "fixed" ||
                            costAssignmentState.mode === "Fixed" ? (
                              <DecidingFactor
                                label="Total Cost"
                                placeholder="Total Cost"
                                name="totalCost"
                                value={costAssignmentState.totalCost}
                                requirements={costAssignmentState}
                                handleCostAssignmentDialogState={
                                  handleCostAssignmentDialogState
                                }
                                handleIncludeGst={
                                  costAssignmentState.handleIncludeGst
                                }
                                addResourceButton={
                                  <Button
                                    startIcon={<AddIcon />}
                                    variant="contained"
                                    onClick={() => {
                                      console.log("zero", costAssignmentState);
                                      if (
                                        parseInt(
                                          costAssignmentState.totalCost
                                        ) === 0
                                      ) {
                                        toast.warn(
                                          "Total cost can not be zero"
                                        );
                                        return;
                                      } else {
                                        costAssignmentState.insertRevenueOneByOne();
                                      }
                                    }}
                                    sx={{ width: "30%" }}
                                    disabled={
                                      !costAssignmentState.totalCost ||
                                      !costAssignmentState.currency ||
                                      !costAssignmentState.company ||
                                      !costAssignmentState.account
                                    }
                                  >
                                    Add MileStone
                                  </Button>
                                }
                              />
                            ) : (
                              <DecidingFactor
                                label="Number Of Resources"
                                placeholder="Number Of Resources"
                                name="numberofResources"
                                value={costAssignmentState.numberofResources}
                                requirements={costAssignmentState}
                                isCreateMode={false}
                                handleCostAssignmentDialogState={
                                  handleCostAssignmentDialogState
                                }
                                handleIncludeGst={
                                  costAssignmentState.handleIncludeGst
                                }
                                addResourceButton={
                                  <Button
                                    startIcon={<AddIcon />}
                                    variant="contained"
                                    onClick={
                                      costAssignmentState.insertRevenueOneByOne
                                    }
                                    sx={{ width: "30%" }}
                                    disabled={
                                      !costAssignmentState.numberofResources ||
                                      !costAssignmentState.currency
                                    }
                                  >
                                    Add Resource
                                  </Button>
                                }
                              />
                            )
                          ) : null}
                        </Grid>
                        {
                          // costAssignmentState.totalCost !== "" &&
                          costAssignmentState.mode === "fixed" &&
                          costAssignmentState.revenues.length ? (
                            <Grid item mt={1} xs={12}>
                              <Grid container spacing={1.2}>
                                {costAssignmentState.revenues.map(
                                  (ele, index) => {
                                    return (
                                      <>
                                        {costAssignmentState.mode ===
                                        "fixed" ? (
                                          <Grid
                                            item
                                            xs={
                                              costAssignmentState.mode ===
                                              "fixed"
                                                ? 1
                                                : 0
                                            }
                                          >
                                            <IconButton
                                              size="small"
                                              sx={{ mt: "11.5px" }}
                                              onClick={() => {
                                                costAssignmentState.removeRevenues(
                                                  costAssignmentState.revenues,
                                                  index
                                                );
                                              }}
                                            >
                                              <RemoveIcon
                                                sx={{ color: "red" }}
                                              />
                                            </IconButton>
                                          </Grid>
                                        ) : null}
                                        <Grid
                                          item
                                          xs={
                                            costAssignmentState.mode === "fixed"
                                              ? 11
                                              : 12
                                          }
                                        >
                                          {" "}
                                          <CostDistributingListing
                                            key={index}
                                            costAndResources={ele}
                                            index={index}
                                            requirements={costAssignmentState}
                                            fieldHandler={
                                              handleCostAndInvoiceDescription
                                            }
                                          />{" "}
                                        </Grid>
                                      </>
                                    );
                                  }
                                )}
                              </Grid>
                            </Grid>
                          ) : (
                            <Grid item mt={1} xs={12}>
                              <Grid container spacing={1.2}>
                                {costAssignmentState.tmRevenues.map(
                                  (ele, index) => {
                                    return (
                                      <>
                                        {costAssignmentState.mode !==
                                        "fixed" ? (
                                          <Grid
                                            item
                                            xs={
                                              costAssignmentState.mode !==
                                              "fixed"
                                                ? 1
                                                : 0
                                            }
                                          >
                                            <IconButton
                                              size="small"
                                              sx={{ mt: "11.5px" }}
                                              onClick={() => {
                                                costAssignmentState.removeTmRevenues(
                                                  costAssignmentState.tmRevenues,
                                                  index
                                                );
                                              }}
                                            >
                                              <RemoveIcon
                                                sx={{ color: "red" }}
                                              />
                                            </IconButton>
                                          </Grid>
                                        ) : null}
                                        <Grid
                                          item
                                          xs={
                                            costAssignmentState.mode !== "fixed"
                                              ? 11
                                              : 12
                                          }
                                        >
                                          {" "}
                                          <CostDistributingListing
                                            key={index}
                                            costAndResources={ele}
                                            index={index}
                                            requirements={costAssignmentState}
                                            fieldHandler={
                                              handleCostAndInvoiceDescription
                                            }
                                          />{" "}
                                        </Grid>
                                      </>
                                    );
                                  }
                                )}
                              </Grid>
                            </Grid>
                          )
                        }
                        {costAssignmentState.mode === "fixed" &&
                        costAssignmentState.revenues.length ? (
                          <Grid
                            sx={{
                              display: "flex",
                              flexDirection: "row-reverse",
                            }}
                            item
                            xs={12}
                          >
                            <Button
                              startIcon={<AddIcon />}
                              variant="contained"
                              onClick={
                                costAssignmentState.insertRevenueOneByOne
                              }
                              disabled={
                                !(
                                  costAssignmentState.revenues.filter(
                                    (ele) =>
                                      // ele.cost &&
                                      ele.invoiceDescription
                                  ).length ===
                                  costAssignmentState.revenues.length
                                ) || handleReadonlyMileStone()
                              }
                            >
                              Add MileStone
                            </Button>
                          </Grid>
                        ) : costAssignmentState.tmRevenues.length ? (
                          <Grid
                            sx={{
                              display: "flex",
                              flexDirection: "row-reverse",
                            }}
                            item
                            xs={12}
                          >
                            <Button
                              startIcon={<AddIcon />}
                              variant="contained"
                              onClick={
                                costAssignmentState.insertTmRevenueOneByOne
                              }
                              disabled={
                                !(
                                  costAssignmentState.tmRevenues.filter(
                                    (ele) =>
                                      // ele.cost &&
                                      ele.invoiceDescription &&
                                      ele.paymentCycle &&
                                      ele.resourceName
                                  ).length ===
                                  costAssignmentState.tmRevenues.length
                                )
                              }
                            >
                              Add resource
                            </Button>
                          </Grid>
                        ) : null}
                      </Grid>
                    </>
                  </div>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button
                onClick={() => {
                  costAssignmentState.onClose();
                  fetchCostEstimation(project.leadId);
                  setHomeCompany("");
                  setHomeAccount("");
                }}
              >
                CANCEL
              </Button>
              {checkCostEmptyOrNot(costEstimationState) ? (
                <Button
                  disabled={checkIsEmpty(costAssignmentState.mode)}
                  onClick={handleAdd}
                >
                  SAVE
                </Button>
              ) : (
                <Button
                  disabled={checkIsEmpty(costAssignmentState.mode)}
                  onClick={handleCreate}
                >
                  SAVE
                </Button>
              )}
            </DialogActions>
          </Dialog>

          {currentUser?.role === "ADMIN" ||
          currentUser._id === project.contact.salesOwner ? (
            <Grid item xs={12}>
              <Card
                sx={{ backgroundColor: "#f1f2f6" }}
                elevation={5}
                variant="outlined"
              >
                <CardContent>
                  <Grid container spacing={1}>
                    {/* <Grid item xs={11.25} sm={11.25}> */}
                    <Grid item xs={12} sm={12}>
                      <Typography variant="h5" gutterBottom color="primary">
                        PRICE INFORMATION
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Card elevation={3} variant="outlined">
                        <Grid
                          item
                          xs={11.8}
                          sx={{
                            display: "flex",
                            flexDirection: "row-reverse",
                            marginTop: "30px",
                          }}
                        >
                          {checkCostEmptyOrNot(costEstimationState) ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row-reverse",
                              }}
                            >
                              <Button
                                startIcon={<ModeEditIcon />}
                                sx={{ maxHeight: "45px" }}
                                fontSize="medium"
                                // style={{ marginRight: "-42px" }}
                                variant="contained"
                                size="large"
                                onClick={async () => {
                                  setLoaderDialog(true);
                                  const response = await getContactById(
                                    project.contactId
                                  );
                                  if (response.data.status) {
                                    setLoaderDialog(false);
                                    const { companyCountry } =
                                      response.data.data;
                                    if (
                                      companyCountry?.toLowerCase() === "india"
                                    ) {
                                      if (
                                        costAssignmentState.homeCompanyCountry?.toLowerCase() !==
                                        "india"
                                      ) {
                                        setCostAssignmentState((prev) => ({
                                          ...prev,
                                          open: true,
                                          gstApplicable: false,
                                        }));
                                        return;
                                      }
                                      setCostAssignmentState((prev) => ({
                                        ...prev,
                                        open: true,
                                        gstApplicable: true,
                                      }));
                                      return;
                                    }
                                    if (!companyCountry) {
                                      if (
                                        costAssignmentState.homeCompanyCountry?.toLowerCase() ===
                                        "india"
                                      ) {
                                        setCostAssignmentState((prev) => ({
                                          ...prev,
                                          open: true,
                                          gstApplicable: true,
                                        }));
                                        return;
                                      }
                                      setCostAssignmentState((prev) => ({
                                        ...prev,
                                        open: true,
                                        gstApplicable: false,
                                      }));
                                    }
                                  }
                                  setLoaderDialog(false);
                                  setCostAssignmentState((prev) => ({
                                    ...prev,
                                    open: true,
                                  }));
                                }}
                              >
                                Edit
                              </Button>
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row-reverse",
                              }}
                            >
                              <>
                                <Button
                                  startIcon={<AddIcon />}
                                  sx={{ maxHeight: "45px" }}
                                  fontSize="medium"
                                  // style={{ marginRight: "-42px" }}
                                  variant="contained"
                                  size="large"
                                  onClick={async () => {
                                    setLoaderDialog(true);
                                    const response = await getContactById(
                                      project.contactId
                                    );
                                    if (response.data.status) {
                                      setLoaderDialog(false);
                                      const { companyCountry } =
                                        response.data.data;
                                      if (
                                        companyCountry?.toLowerCase() ===
                                        "india"
                                      ) {
                                        if (
                                          costAssignmentState.homeCompanyCountry?.toLowerCase() !==
                                          "india"
                                        ) {
                                          setCostAssignmentState((prev) => ({
                                            ...prev,
                                            open: true,
                                            gstApplicable: false,
                                          }));
                                          return;
                                        }
                                        setCostAssignmentState((prev) => ({
                                          ...prev,
                                          open: true,
                                          gstApplicable: true,
                                        }));
                                        return;
                                      }
                                      if (!companyCountry) {
                                        if (
                                          costAssignmentState.homeCompanyCountry?.toLowerCase() ===
                                          "india"
                                        ) {
                                          setCostAssignmentState((prev) => ({
                                            ...prev,
                                            open: true,
                                            gstApplicable: true,
                                          }));
                                          return;
                                        }
                                        setCostAssignmentState((prev) => ({
                                          ...prev,
                                          open: true,
                                          gstApplicable: false,
                                        }));
                                      }
                                    }
                                    setLoaderDialog(false);
                                    setCostAssignmentState((prev) => ({
                                      ...prev,
                                      open: true,
                                    }));
                                  }}
                                >
                                  Add
                                </Button>
                              </>
                            </div>
                          )}
                        </Grid>
                        <CardContent>
                          <Grid container spacing={2}>
                            {checkCostEmptyOrNot(costEstimationState) ? (
                              costLoader ? (
                                <CircularProgress />
                              ) : (
                                <Grid item xs={12}>
                                  <CostEstimationView
                                    fetchCostEstimation={fetchCostEstimation}
                                    project={project}
                                    costInforMations={costEstimationState}
                                    gstValues={gstValues}
                                    projectId={projectId}
                                    setLoaderDialog={setLoaderDialog}
                                    getInvoiceDetails={getInvoiceDetails}
                                    parentCompanyDetails={parentCompanyDetails}
                                    isEditIcon={isEditIcon}
                                    invoiceDetailsAndManage={
                                      invoiceDetailsAndManage.data
                                    }
                                  />
                                </Grid>
                              )
                            ) : (
                              <Grid item xs={12}>
                                <Typography
                                  textAlign="center"
                                  fontWeight="700"
                                  color="#857C7A"
                                >
                                  {`No Cost available, please add your cost Estimation.`}
                                </Typography>
                              </Grid>
                            )}

                            {currentUser?.role === "ADMIN" ||
                            currentUser._id === project.contact.salesOwner ? (
                              <Grid item xs={12}>
                                {invoiceDetailsAndManage.loaderEnable ? (
                                  <LinearProgress />
                                ) : !invoiceDetailsAndManage.loaderEnable &&
                                  invoiceDetailsAndManage.data.length ? (
                                  <InvoiceManageTable
                                    invoiceDetails={
                                      invoiceDetailsAndManage.data
                                    }
                                    openMenu={invoiceDetailsAndManage.openMenu}
                                    allowOpenMenu={
                                      invoiceDetailsAndManage.allowOpenMenu
                                    }
                                    allowCloseMenu={
                                      invoiceDetailsAndManage.allowCloseMenu
                                    }
                                    downloadPdf={
                                      invoiceDetailsAndManage.downloadPdf
                                    }
                                    cancelInvoiceAPI={
                                      invoiceDetailsAndManage.cancelInvoice
                                    }
                                    payInvoiceAPI={
                                      invoiceDetailsAndManage.payInvoice
                                    }
                                    regenerateInvoiceAPI={
                                      invoiceDetailsAndManage.regenerateInvoice
                                    }
                                    showLogs={showLogs}
                                    leadId={project.leadId}
                                    getInvoiceDetails={getInvoiceDetails}
                                    gstValues={gstValues}
                                    costInforMations={costEstimationState}
                                    costAssignmentState={costAssignmentState}
                                    parentCompanyDetails={parentCompanyDetails}
                                    setLoaderDialog={setLoaderDialog}
                                  />
                                ) : null}
                              </Grid>
                            ) : null}
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              {/* )} */}
            </Grid>
          ) : null}

          <Grid item xs={12} sm={12}>
            <Card
              sx={{ backgroundColor: "#f1f2f6" }}
              elevation={5}
              variant="outlined"
            >
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6}>
                    <Typography variant="h5" gutterBottom color="primary">
                      RESOURCES
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TeamSection
                      resource={resource}
                      setResource={setResource}
                      toEdit={toEdit}
                      setToEdit={setToEdit}
                      isProjectEditPage={isProjectEditPage}
                      tempProjectTeam={tempProjectTeam}
                      setTempProjectTeam={setTempProjectTeam}
                      teamSingle={teamSingle}
                      setTeamSingle={setTeamSingle}
                      projectId={projectId}
                      setIsProjectFetched={setIsProjectFetched}
                      currentUser={currentUser}
                      project={project}
                      setIactionedApiCalled={setIactionedApiCalled}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Card
              sx={{ backgroundColor: "#f1f2f6" }}
              elevation={5}
              variant="outlined"
            >
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="h5" gutterBottom color="primary">
                      {`${projectDynamicTitel?.displayName.singularName.toUpperCase()} STATUS`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Card elevation={3} variant="outlined">
                      <CardContent>
                        <Grid
                          container
                          spacing={1}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={11} sm={11}>
                            {`Status : `}
                            {project.status && (
                              <span style={{ color: "#2065D1" }}>
                                {project.status.name === "Waiting to start"
                                  ? project.status.name
                                  : capitalizedString(project.status.name)}
                              </span>
                            )}{" "}
                            {project.status.name !== "Waiting to start" && (
                              <>
                                {`on ${formattedDateTime}`}
                                {currentUser &&
                                  currentUser.role === "ADMIN" &&
                                  project.status.name.toLowerCase() !==
                                    "closed" && (
                                    <Tooltip
                                      title={"Signed Contract"}
                                      placement="top-start"
                                    >
                                      <IconButton
                                        style={{ color: "#2065D1" }}
                                        onClick={() => {
                                          setOpenProjectSignedContarctDialouge(
                                            true
                                          );
                                        }}
                                      >
                                        <InfoIcon />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                              </>
                            )}
                          </Grid>

                          <Grid item xs={1} sm={1}>
                            {project.status.name.toLowerCase() === "started" ? (
                              <Grid container spacing={1}>
                                {currentUser &&
                                  currentUser.role === "ADMIN" && (
                                    <>
                                      <Grid item xs={6} sm={6}>
                                        {" "}
                                        <Tooltip
                                          title={"Pause"}
                                          placement="top-start"
                                        >
                                          <IconButton
                                            sx={{
                                              maxHeight: "45px",
                                              "&:hover": { color: "#2065D1" },
                                            }}
                                            fontSize="medium"
                                            variant="contained"
                                            size="large"
                                            onClick={() => {
                                              setProject((prev) => {
                                                let temp = prev;
                                                temp.status.name = "Paused";
                                                return temp;
                                              });
                                              setIsEditIcon(true);
                                              updateStatus();
                                            }}
                                          >
                                            <PauseIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </Grid>
                                      <Grid item xs={6} sm={6}>
                                        <Tooltip
                                          title={"Close"}
                                          placement="top-start"
                                        >
                                          <IconButton
                                            sx={{
                                              maxHeight: "45px",
                                              "&:hover": { color: "#2065D1" },
                                            }}
                                            fontSize="medium"
                                            variant="contained"
                                            size="large"
                                            onClick={() => {
                                              setProject((prev) => {
                                                let temp = prev;
                                                temp.status.name = "Closed";
                                                return temp;
                                              });
                                              setIsEditIcon(true);
                                              updateStatus();
                                            }}
                                          >
                                            <CloseIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </Grid>
                                    </>
                                  )}
                              </Grid>
                            ) : project.status.name.toLowerCase() ===
                              "paused" ? (
                              <Grid container spacing={1}>
                                {currentUser &&
                                  currentUser.role === "ADMIN" && (
                                    <>
                                      <Grid item xs={6} sm={6}>
                                        <Tooltip
                                          title={"Restart"}
                                          placement="top-start"
                                        >
                                          <IconButton
                                            sx={{
                                              maxHeight: "45px",
                                              display: "flex",

                                              "&:hover": { color: "#2065D1" },
                                            }}
                                            fontSize="medium"
                                            variant="contained"
                                            size="large"
                                            onClick={() => {
                                              setProject((prev) => {
                                                let temp = prev;
                                                temp.status.name = "Started";
                                                return temp;
                                              });
                                              setIsEditIcon(true);
                                              updateStatus();
                                            }}
                                          >
                                            <RestartAltIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </Grid>
                                      <Grid item xs={6} sm={6}>
                                        {" "}
                                        <Tooltip
                                          title={"Close"}
                                          placement="top-start"
                                        >
                                          <IconButton
                                            sx={{
                                              maxHeight: "45px",
                                              "&:hover": { color: "#2065D1" },
                                            }}
                                            fontSize="medium"
                                            // style={{ marginRight: "-42px" }}
                                            variant="contained"
                                            size="large"
                                            onClick={() => {
                                              setProject((prev) => {
                                                let temp = prev;
                                                temp.status.name = "Closed";
                                                return temp;
                                              });
                                              setIsEditIcon(true);
                                              updateStatus();
                                            }}
                                          >
                                            <CloseIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </Grid>
                                    </>
                                  )}
                              </Grid>
                            ) : project.status.name.toLowerCase() ===
                              "closed" ? null : (
                              currentUser &&
                              currentUser.role === "ADMIN" && (
                                <Grid container spacing={1}>
                                  <Grid item xs={6} sm={6}></Grid>
                                  <Grid item xs={6} sm={6}>
                                    <Tooltip
                                      title={"Start"}
                                      placement={"top-start"}
                                    >
                                      <IconButton
                                        sx={{
                                          maxHeight: "45px",
                                          "&:hover": { color: "#2065D1" },
                                        }}
                                        fontSize="medium"
                                        variant="contained"
                                        size="large"
                                        onClick={() => {
                                          setOpenProjectStatusDialouge(
                                            (prev) => !prev
                                          );
                                        }}
                                      >
                                        <PlayArrowIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                </Grid>
                              )
                            )}

                            {/* </FormControl> */}
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Card
              container
              sx={{ backgroundColor: "#f1f2f6" }}
              elevation={5}
              variant="outlined"
            >
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom color="primary">
                      ACTIONS
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <ActionSection
                      projectId={projectId}
                      action={action}
                      setAction={setAction}
                      initialActionState={initialActionState}
                      isEditPage={isEditPage}
                      isProjectEditPage={isProjectEditPage}
                      loadedActions={loadedActions}
                      setLoadedActions={setLoadedActions}
                      actionType={constants.PROJECTS}
                      checked={checked}
                      setChecked={setChecked}
                      fromProjectPage={true}
                      allDoers={allDoers}
                      techOwner={project.techOwner}
                      salesOwner={project.salesOwner}
                      iactionedApiCalled={iactionedApiCalled}
                      setIactionedApiCalled={setIactionedApiCalled}
                      state={state}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/*Documents  */}
          <Grid item xs={12} sm={12}>
            <Card
              container
              sx={{ backgroundColor: "#f1f2f6" }}
              elevation={5}
              variant="outlined"
            >
              <CardContent>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={10}
                    sm={10}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography variant="h5" gutterBottom color="primary">
                      DOCUMENTS
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sm={2}
                    sx={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      alignItems: "center",
                    }}
                  >
                    <div className="file-cards">
                      <div className="file-inputs">
                        <Button
                          variant="contained"
                          size="large"
                          startIcon={<AddIcon />}
                          onClick={handleDocClickOpen}
                        >
                          Upload
                        </Button>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Card elevation={3} variant="outlined">
                      <CardContent>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={12}>
                            <FileList
                              projectId={projectId}
                              fromProjectViewPage={true}
                              setFiles={setFiles}
                              fromProjectCreatePage={false}
                              fileList={project.documents}
                              setIsProjectFetched={setIsProjectFetched}
                              currentUser={currentUser}
                              techOwner={project.techOwner}
                              removeFile={() => {}}
                              setIactionedApiCalled={setIactionedApiCalled}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6}></Grid>
                </Grid>
                <Dialog maxWidth="md" open={docOpen} onClose={handleClose}>
                  <DialogContent>
                    <FileUpload
                      files={files}
                      setFiles={setFiles}
                      descriptionObj={descriptionObj}
                      setDescriptionObj={setDescriptionObj}
                      fromProjectViewPage={true}
                      currentUser={currentUser}
                      project={project}
                      isFromComment={false}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleDocClose}>Cancel</Button>
                    <Button disabled={saveButton} onClick={submitFile}>
                      Save
                    </Button>
                  </DialogActions>
                </Dialog>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Card
              container
              sx={{ backgroundColor: "#f1f2f6" }}
              elevation={5}
              variant="outlined"
            >
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom color="primary">
                      COMMENTS
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <CommentSection
                      id={projectId}
                      comment={comment}
                      setComment={setComment}
                      loadedComments={loadedComments}
                      setLoadedComments={setLoadedComments}
                      initialCommentState={initialCommentState}
                      commentType={constants.PROJECTS}
                      fromProjectPage={true}
                      techOwner={project.techOwner}
                      salesOwner={project.salesOwner}
                      allDoers={allDoers}
                      setIactionedApiCalled={setIactionedApiCalled}
                      handleClickOpen={handleClickOpen}
                      handleClose={handleClose}
                      descriptionObj={descriptionObj}
                      files={files}
                      setDescriptionObj={setDescriptionObj}
                      setFiles={setFiles}
                      setLoaderDialog={setLoaderDialog}
                    />
                  </Grid>
                </Grid>
                <Dialog maxWidth="md" open={open} onClose={handleClose}>
                  <DialogContent>
                    <FileUpload
                      files={files}
                      setFiles={setFiles}
                      descriptionObj={descriptionObj}
                      setDescriptionObj={setDescriptionObj}
                      fromProjectViewPage={true}
                      isFromComment={true}
                      currentUser={currentUser}
                      project={project}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button disabled={saveButton} onClick={submitDocComment}>
                      Save
                    </Button>
                  </DialogActions>
                </Dialog>
              </CardContent>
            </Card>
          </Grid>
          <Dialog open={loaderDialog}>
            <DialogTitle></DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              </DialogContentText>
            </DialogContent>
            <DialogActions
              sx={{
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingBottom: "20px",
              }}
            >
              Loading... Please Wait
            </DialogActions>
          </Dialog>
        </Grid>
      </Container>
      <Dialog maxWidth="md" open={openProjectStatusDialouge} onClose={() => {}}>
        <DialogContent>
          <FileUpload
            files={files}
            setFiles={setFiles}
            descriptionObj={descriptionObj}
            setDescriptionObj={setDescriptionObj}
            fromProjectViewPage={true}
            currentUser={currentUser}
            project={project}
            openProjectStatusDialouge={openProjectStatusDialouge}
            signedContractFiles={signedContractFiles}
            setSignedContractFiles={setSignedContractFiles}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSignedContract}>Cancel</Button>
          <Button disabled={saveButton} onClick={submitUploadSignedContract}>
            Start
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openProjectSignedContarctDialouge}
        onClose={() => setOpenProjectSignedContarctDialouge(false)}
      >
        <DialogContent>
          <div
            className="parent"
            style={{
              border: "1px solid black",
              borderRadius: "5px",
              padding: "1em 1.5em",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              className="file-item"
              style={{
                listStyle: "none",
                backgroundColor: "#2065d1 38",
                display: "flex",
                alignItems: "center",
              }}
            >
              <DescriptionIcon sx={{ color: "#2065d1" }} />

              <Tooltip title={fileName}>
                <p
                  style={{
                    flex: 1,
                    fontSize: "0.9rem",
                    paddingLeft: "10px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {fileName.split("-").slice(1).join("-")}
                </p>
              </Tooltip>

              <div
                className="actions"
                style={{
                  justifySelf: "flex-end",
                  cursor: "pointer",
                }}
              >
                <IconButton size="medium" disableRipple="true">
                  <DownloadIcon
                    icon={faDownload}
                    style={{ color: "#f55e30" }}
                    onClick={() => {
                      window.open(project.status.signedContractUrl, "Download");

                      download(project.status.signedContractUrl, fileName);
                    }}
                  />
                </IconButton>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
