import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { fetchSurroundingSkills } from "../../services/common.service";

const SkillTree = ({ skillName, setSkillName }) => {
  const [treeData, setTreeData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const svgRef = useRef(null);
  console.log("treeData", treeData);
  useEffect(() => {
    if (skillName) {
      fetchTreeData(skillName);
    }
  }, [skillName]);

  const fetchTreeData = async (name) => {
    setLoading(true);
    setError(null); // Clear any previous errors
    try {
      const response = await fetchSurroundingSkills(name); // Fetching skill data
      console.log("respoonse", response);
      if (response?.status && response?.data?.data[0]) {
        setTreeData(response.data.data[0]);
      } else {
        throw new Error(
          response?.message || "Failed to fetch skill tree data."
        );
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!treeData) return;

    const svg = d3.select(svgRef.current);
    const width = 1200;
    const height = 650;

    svg
      .attr("width", "100%")
      .attr("height", "100%")
      .attr("viewBox", `0 0 ${width} ${height}`);

    // Clear previous SVG contents
    svg.selectAll("*").remove();

    // // Create tooltip
    // const tooltip = d3
    //   .select("body")
    //   .append("div")
    //   .style("position", "absolute")
    //   .style("background", "white")
    //   .style("border", "1px solid #ccc")
    //   .style("padding", "5px 10px")
    //   .style("border-radius", "4px")
    //   .style("visibility", "hidden");

    // Filter out invalid links
    const validLinks = treeData.links.filter(
      (link) => link.source && link.target
    );

    // Create simulation
    const simulation = d3
      .forceSimulation(treeData.nodes)
      .force(
        "link",
        d3
          .forceLink(validLinks)
          .id((d) => d._id)
          .distance(120)
      )
      .force("charge", d3.forceManyBody().strength(-500))
      .force("center", d3.forceCenter(width / 2, height / 2))
      .force("collision", d3.forceCollide(60)); // Avoid overlapping

    // Add links
    const link = svg
      .append("g")
      .attr("stroke", "#aaa")
      .attr("stroke-width", 2)
      .selectAll("line")
      .data(validLinks)
      .enter()
      .append("line");

    // Add nodes
    const node = svg
      .append("g")
      .attr("stroke", "#fff")
      .attr("stroke-width", 1.5)
      .selectAll("circle")
      .data(treeData.nodes)
      .enter()
      .append("circle")
      .attr("r", 40) // circle radius
      .attr("fill", (d) => (d._id === skillName ? "#ff5733" : "#69b3a2"))
      .call(
        d3
          .drag()
          .on("start", (event, d) => {
            if (!event.active) simulation.alphaTarget(0.3).restart();
            d.fx = d.x;
            d.fy = d.y;
          })
          .on("drag", (event, d) => {
            d.fx = event.x;
            d.fy = event.y;
          })
          .on("end", (event, d) => {
            if (!event.active) simulation.alphaTarget(0);
            d.fx = null;
            d.fy = null;
          })
      )
      // .on("mouseover", (event, d) => {
      //   tooltip
      //     .style("visibility", "visible")
      //     .html(`<strong>Skill:</strong> ${d._id}<br/><strong>`);
      // })
      // .on("mousemove", (event) => {
      //   tooltip
      //     .style("top", `${event.pageY - 10}px`)
      //     .style("left", `${event.pageX + 10}px`);
      // })
      // .on("mouseout", () => {
      //   tooltip.style("visibility", "hidden");
      // })
      .on("click", (event, d) => {
        setSkillName(d._id); // Update the skill name
      });

    // Add labels
    const labels = svg
      .append("g")
      .selectAll("text")
      .data(treeData.nodes)
      .enter()
      .append("text")
      .text((d) => d._id)
      .attr("font-size", "12px")
      .attr("text-anchor", "middle")
      .attr("dy", 30) // Position label slightly above the node
      .style("pointer-events", "none") // Prevent label from interfering with drag events
      .attr("fill", "#333");

    // Update positions on tick
    simulation.on("tick", () => {
      link
        .attr("x1", (d) => d.source.x)
        .attr("y1", (d) => d.source.y)
        .attr("x2", (d) => d.target.x)
        .attr("y2", (d) => d.target.y);

      node.attr("cx", (d) => d.x).attr("cy", (d) => d.y);
      labels.attr("x", (d) => d.x).attr("y", (d) => d.y - 25); // Keep labels above nodes
    });

    return () => simulation.stop();
  }, [treeData, skillName]); // Dependencies ensure the effect runs when data changes

  // Handling loading and error states
  if (loading) return <p>Loading skill tree...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!treeData) return <p>No data to display.</p>;

  return <svg ref={svgRef} width="800" height="600" />;
};

export default SkillTree;
