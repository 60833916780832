// @mui
import { useTheme } from "@mui/material/styles";
import {
  Grid,
  Container,
  Typography,
  Card,
  CardContent,
  Chip,
  Badge,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import img2 from "../images/nodata-removebg-preview.png";

import * as React from "react";
// import Button from "@mui/material/Button";

// components
import Page from "../components/UI/Page";
// sections
// import LeadCreate from "../components/lead/lead.create";
// ----------------------------------------------------------------------
import DashboardActions from "../components/Dashboard/DashboardActionList";
import DashboardProjects from "../components/Dashboard/DashboardProjectList";
import { useEffect, useState } from "react";
import {
  deleteSkill,
  getAllSkills,
  getEntityName,
  getMyActions,
  skillApprovance,
} from "../services/common.service";
import { getMyProject } from "../services/common.service";
import { AuthContext } from "../context/auth.context";

//-------------------------------------------------------------------------

import { toast, ToastContainer } from "react-toastify";
import { ActionContext } from "../context/action.context";
import { useNavigate } from "react-router-dom";
import { getWatcherLeadList } from "../services/contacts.service";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import SkillApprovanceChip from "../components/Shared/Skill/SkillApprovanceChip";
import { getBenchedResources } from "../services/resources.service";

export default function DashboardApp() {
  const { currentUser, entityName, setEntityName } =
    React.useContext(AuthContext);
  const navigate = useNavigate();
  const { refetchAction } = React.useContext(ActionContext);
  const [leadWatcherList, setLeadWatcherList] = useState([]);
  const theme = useTheme();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const oldDate = new Date();
  oldDate.setDate(oldDate.getDate() - 2);

  const [actionList, setActionList] = useState([]);
  const [separatedActions, setSeparatedActions] = useState({
    dueToday: [],
    pendingUntilToday: [],
  });
  const [isLoading, setIsLoading] = useState({
    forAction: false,
    forResource: false,
    handleActionLoader: (forAction = true) => {
      setIsLoading((prev) => ({
        ...prev,
        forAction,
      }));
    },
    handleResourceLoader: (forResource = true) => {
      setIsLoading((prev) => ({
        ...prev,
        forResource,
      }));
    },
  });
  const [benchedResources, setBenchedResources] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [approvedSkills, setApprovedSkills] = useState([]);
  const [skillsState, setSkillsState] = useState({
    skills: [],
    count: 0,
  });
  const [showSkills, setShowSkills] = useState(false);
  const [onRejectButtonCLick, setOnRejectButtonCLick] = useState({
    open: false,
    skillId: "",
    replacedBy: "",
    openDialog: (skillId) => {
      setOnRejectButtonCLick((prev) => ({
        ...prev,
        open: true,
        skillId,
      }));
    },
    closeDialog: () => {
      setOnRejectButtonCLick((prev) => ({
        ...prev,
        open: false,
        skillId: "",
        replacedBy: "",
      }));
    },
  });

  const message = "Hi good morning";

  const dateMatch = (date) => {
    const dueDate = new Date(date).toDateString();
    const todayDate = new Date().toDateString();
    if (dueDate === todayDate) {
      return true;
    }
    return false;
  };

  const pastDateMatch = (date) => {
    const dueDate = new Date(date).setHours(0, 0, 0, 0);
    const todayDate = new Date().setHours(0, 0, 0, 0);
    if (dueDate < todayDate) {
      return true;
    }
  };

  // Fetch current loggedin user's Actions

  const fetchActions = () => {
    isLoading.handleActionLoader(true);
    getMyActions()
      .then((res) => {
        if (res.data.status) {
          setActionList([]);
          setSeparatedActions({
            dueToday: [],
            pendingUntilToday: [],
          });
          setActionList(res.data.data);
          const dueToday = [],
            pendingUntilToday = [];
          for (const data of res.data.data) {
            if (dateMatch(data.dueDate)) {
              dueToday.push(data);
            }
            if (pastDateMatch(data.dueDate)) {
              pendingUntilToday.push(data);
            }
          }
          setSeparatedActions(
            (prev) => ({}, { ...prev, dueToday, pendingUntilToday })
          );
          console.log(res);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        isLoading.handleActionLoader(false);
      });
  };

  //Fetch current loggedin user's  Projects

  const fetchProjects = async () => {
    await getMyProject()
      .then((response) => {
        console.log(response);
        if (response.data.status) {
          setProjectList(response.data.data);
          console.log("response", response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //fetch where i watcher

  const fetchWatcherLeadList = () => {
    getWatcherLeadList()
      .then((response) => {
        console.log(response);
        if (response.data.status) {
          setLeadWatcherList(response.data.data);
          console.log("result getwatcher list", response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClickWatcherList = (row) => {
    let { contactId, _id } = row;
    console.log("row", row);
    // actionType === constants.CONTACTS
    //   ? navigate(`/contacts/${id}`, { replace: true })
    //   : navigate(`/Lead/${leadId}/${id}`, { replace: true });
    navigate(`/Leads/${_id}/${contactId}`, { replace: true });
  };

  //Entity Name Config

  const responseData = function () {
    getEntityName()
      .then((response) => {
        if (response) {
          const entityId = response?.data.data[0]._id;

          let result = response?.data.data.slice(1);
          result = result.map((item, index) => ({
            ...item,
            id: index + 1,
            toEdit: false,
            entityId: entityId,
          }));

          setEntityName(result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchSkills = async () => {
    try {
      const [unApprovedSkills, approvedSkills] = await Promise.all([
        getAllSkills("PENDING"),
        getAllSkills("APPROVED"),
      ]);
      if (unApprovedSkills.data.status || approvedSkills.data.status) {
        setApprovedSkills(approvedSkills.data?.data?.skills ?? []);
        setSkillsState((prev) => ({
          ...prev,
          skills: unApprovedSkills.data?.data?.skills ?? [],
          count: unApprovedSkills.data?.data?.count ?? 0,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function onApproveorReject({ skillId, isApproved, replacedBy = "" }) {
    try {
      const result = await skillApprovance(
        skillId,
        Object.assign({ isApproved }, isApproved ? {} : { replacedBy })
      );
      if (result.data.status) {
        await fetchSkills();
        if (onRejectButtonCLick.open) onRejectButtonCLick.closeDialog();
        toast.success(result.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message ?? error.message);
    }
  }

  async function onDeleteSkill(skillId) {
    try {
      const response = await deleteSkill(skillId);
      if (response.data.status) {
        await fetchSkills();
        onRejectButtonCLick.closeDialog();
        toast.success(
          response.data.data.message ??
            response.data.message ??
            "Skill Deleted Successfully"
        );
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message ?? error.message);
    }
  }

  async function fetchBenchedResources() {
    isLoading.handleResourceLoader(true);
    try {
      const result = await getBenchedResources();
      if (result.data?.status && result.data?.code === 200) {
        setBenchedResources(result.data?.data ?? []);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      isLoading.handleResourceLoader(false);
    }
  }

  useEffect(() => {
    if (currentUser && currentUser?.role !== "SUPER_ADMIN") {
      (async function () {
        await Promise.all([
          fetchWatcherLeadList(),
          responseData(),
          fetchSkills(),
          fetchBenchedResources(),
        ]);
      })();
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser && currentUser?.role !== "SUPER_ADMIN") {
      fetchProjects();
      fetchActions();
    }
  }, [refetchAction, currentUser]);

  return (
    <Page title="Dashboard">
      <>
        {currentUser?.role === "SUPER_ADMIN" ? null : (
          <>
            <ToastContainer />
            <Container>
              {/* watcher list */}
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography
                    align="left"
                    variant="h4"
                    style={{ marginBottom: 10 }}
                  >
                    I'm Watching
                  </Typography>
                  {leadWatcherList && (
                    <Card>
                      <CardContent>
                        <Grid container spacing={0}>
                          {leadWatcherList.length ? (
                            <Grid item xs={12}>
                              {leadWatcherList.map((row, index) => (
                                <Chip
                                  label={`${row.summary}`}
                                  style={{ fontWeight: "bold" }}
                                  onClick={() => handleClickWatcherList(row)}
                                  sx={{ marginRight: "10px" }}
                                />
                              ))}
                            </Grid>
                          ) : (
                            <Typography align="center">
                              Currently you aren't watching anything
                            </Typography>
                          )}
                        </Grid>
                      </CardContent>
                    </Card>
                  )}
                </Grid>
                {currentUser?.role?.toUpperCase() === "ADMIN" && (
                  <Grid item xs={12}>
                    <Grid container spacing={0}>
                      <Grid item xs={12}>
                        <Typography
                          align="left"
                          variant="h4"
                          style={{ marginBottom: 10 }}
                        >
                          Skills Awaiting For Approval
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Card>
                          <CardContent>
                            <Grid container spacing={0}>
                              {!showSkills || !skillsState.skills.length ? (
                                <Grid item xs={2}>
                                  <Badge
                                    component={"div"}
                                    color="warning"
                                    badgeContent={skillsState.count}
                                    sx={{ ":hover": { cursor: "pointer" } }}
                                    onClick={() => {
                                      if (!skillsState.count) {
                                        toast(
                                          "No skills are in the awaiting list"
                                        );
                                        return;
                                      }
                                      setShowSkills(true);
                                    }}
                                  >
                                    <WorkHistoryIcon />
                                  </Badge>
                                </Grid>
                              ) : (
                                <Grid item xs={12}>
                                  <Grid container spacing={2}>
                                    {skillsState.skills.map(
                                      ({ _id, name }, index) => (
                                        <Grid item xs={2} key={index}>
                                          <SkillApprovanceChip
                                            skillName={name}
                                            onApprove={() => {
                                              onApproveorReject({
                                                skillId: _id,
                                                isApproved: true,
                                              });
                                            }}
                                            onReject={() => {
                                              onRejectButtonCLick.openDialog(
                                                _id
                                              );
                                            }}
                                          />
                                        </Grid>
                                      )
                                    )}
                                  </Grid>
                                </Grid>
                              )}
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={6}>
                  <Typography
                    align="left"
                    variant="h4"
                    style={{ paddingTop: 10, marginBottom: 10 }}
                  >
                    Actions
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    align="left"
                    variant="h4"
                    style={{ paddingTop: 10, marginBottom: 10 }}
                  >
                    Benched Resources
                  </Typography>
                </Grid>
              </Grid>
            </Container>
            {/* {actionList.length !== 0 ? ( */}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DashboardActions
                  fetchActions={fetchActions}
                  actionList={actionList}
                  separatedActions={separatedActions}
                  head="Actions Assigned"
                  ondate={new Date().toLocaleDateString("en-IN")}
                  benchedResources={benchedResources}
                  isLoading={isLoading}
                />
              </Grid>
            </Grid>
            {/* ) : null} */}
            {projectList.length !== 0 ? (
              <Grid container spacing={2}>
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <DashboardProjects
                    fetchProjects={fetchProjects}
                    projectList={projectList}
                    head="My Projects"
                  />
                </Grid>
              </Grid>
            ) : null}
          </>
        )}
      </>
      {actionList.length === 0 && projectList.length === 0 ? (
        <Grid container spacing={2}>
          <Grid
            className="dashboardGrid"
            display="flex"
            marginTop="150px"
            justifyContent="center"
            alignItems="center"
            minHeight="50vh"
            minWidth="160vh"
          >
            <img src={img2} width="530px" height="500px" />
            {/* <h2 fontSize="150px" color= "#00ff00">Learn for free</h2> */}
          </Grid>
        </Grid>
      ) : null}
      <div></div>
      <Dialog open={onRejectButtonCLick.open}>
        <DialogTitle>
          This rejection process cannot be undone, Are you sure?
        </DialogTitle>
        <DialogContent dividers>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Approved Skills
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={onRejectButtonCLick.replacedBy}
              label="Approved Skills"
              onChange={({ target: { value: replacedBy = "" } } = {}) => {
                setOnRejectButtonCLick((prev) => ({
                  ...prev,
                  replacedBy,
                }));
              }}
            >
              {approvedSkills.map(({ _id, name }, index) => {
                return (
                  <MenuItem key={index} value={_id}>
                    {name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="error"
            onClick={onRejectButtonCLick.closeDialog}
          >
            CANCEL
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              if (
                !onRejectButtonCLick.skillId ||
                !onRejectButtonCLick.replacedBy
              ) {
                toast("Please select an approved skill");
                return;
              }
              onApproveorReject({
                skillId: onRejectButtonCLick.skillId,
                isApproved: false,
                replacedBy: onRejectButtonCLick.replacedBy,
              });
            }}
            disabled={!onRejectButtonCLick.replacedBy}
          >
            REPLACE
          </Button>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              onDeleteSkill(onRejectButtonCLick.skillId);
            }}
            disabled={!!onRejectButtonCLick.replacedBy}
          >
            DELETE
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
}
