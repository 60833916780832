import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const SkillsBubble = ({ data, onBubbleClick }) => {
  const svgRef = useRef(null);

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    const width = 1350;
    const height = 600;

    // Clear previous SVG contents
    svg.selectAll("*").remove();

    // Create a color scale based on skill value
    const colorScale = d3
      .scaleLinear() // we can choose different types of color scale
      .domain(d3.extent(data, (d) => d.value))
      .range(["#ffeda0", "#f03b20"]);

    // Create a size scale for bubbles
    const sizeScale = d3
      .scaleSqrt()
      .domain([0, d3.max(data, (d) => d.value)])
      .range([10, 50]); // Bubble size range (min, max radius)

    // Set up a simulation for the bubbles
    const simulation = d3
      .forceSimulation(data)
      .force(
        "x",
        d3.forceX(width / 2).strength(0.05) // Position bubbles in the center horizontally
      )
      .force(
        "y",
        d3.forceY(height / 2).strength(0.05) // Position bubbles in the center vertically
      )
      .force(
        "collision",
        d3.forceCollide((d) => sizeScale(d.value) + 2) // Prevent overlapping bubbles
      );

    // Create the SVG container
    svg
      .attr("width", width)
      .attr("height", height)
      .style("background-color", "#f9f9f9");

    // Add a tooltip
    const tooltip = d3
      .select("body")
      .append("div")
      .style("position", "absolute")
      .style("background", "white")
      .style("border", "1px solid #ccc")
      .style("padding", "5px 10px")
      .style("border-radius", "4px")
      .style("visibility", "hidden");

    // Add bubbles
    const bubbles = svg
      .selectAll(".bubble")
      .data(data)
      .enter()
      .append("g")
      .attr("class", "bubble")
      .on("click", (event, d) => {
        if (onBubbleClick) {
          onBubbleClick(d.skillName); // Trigger the callback with the skillName
        }
      });

    // Add circles
    bubbles
      .append("circle")
      .attr("r", (d) => sizeScale(d.value)) // Dynamic size
      .attr("fill", (d) => colorScale(d.value)) // Dynamic color
      .on("mouseover", (event, d) => {
        tooltip
          .style("visibility", "visible")
          .html(`<strong>${d.skillName}</strong><br/>Value: ${d.value}`);
      })
      .on("mousemove", (event) => {
        tooltip
          .style("top", `${event.pageY - 10}px`)
          .style("left", `${event.pageX + 10}px`);
      })
      .on("mouseout", () => {
        tooltip.style("visibility", "hidden");
      });

    // Add text labels
    bubbles
      .append("text")
      .text((d) => d.skillName)
      .attr("text-anchor", "middle")
      .attr("alignment-baseline", "middle")
      .attr("font-size", "12px")
      .attr("fill", "#333")
      .attr("y", -8); // Adjust to position above the value text

    // Add text labels for the value
    bubbles
      .append("text")
      .text((d) => d.value)
      .attr("text-anchor", "middle")
      .attr("alignment-baseline", "middle")
      .attr("font-size", "12px")
      .attr("fill", "#111")
      .attr("y", 10); // Adjust to position below the skill name text

    // Update positions dynamically
    simulation.on("tick", () => {
      bubbles.attr("transform", (d) => `translate(${d.x},${d.y})`);
    });

    // Cleanup tooltip on unmount
    return () => tooltip.remove();
  }, [data]);

  return <svg ref={svgRef}></svg>;
};

export default SkillsBubble;
