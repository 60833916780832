import * as React from "react";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import {
  Autocomplete,
  Button,
  ButtonGroup,
  Chip,
  Stack,
  TextField,
} from "@mui/material";
import { getContactSearch } from "../../services/contacts.service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AutoCompleteSearch from "../Autocomplete/AutoCompleteSearch";
import { useDynamicCompanyTitle } from "../../pages/Companies/Companies";
import { useSearchCollectionName } from "../../utils/filterObject";
import { AuthContext } from "../../context/auth.context";

export default function SearchContact({
  setCount,
  contacts,
  setContacts,
  column,
  setColumn,
  typeText,
  setTypeText,
  setPage,

  setPageCount,
  setIsLoading,
  initialIntervals,
  intervals,
  setIntervals,
  handleIntervalChange,
  showChip,
  formatCustomDate,
  startDate,
  endDate,
  afterRemoveChip,
  dateFilteredContacts,
  fetchContact,
  setSelectedContacts = () => {},
  salesOwners,
  getSalesOwners,
  selectedSalesOwners,
  setSelectedSalesOwners,
}) {
  const companyDynamicTitle = useDynamicCompanyTitle();
  const [isEmpty, setIsEmpty] = React.useState(true);
  const contactPage = "contactPage";

  const notify = () => toast("Please Select a column!");
  const handleChange = (event) => {
    setIsEmpty(false);
    const {
      target: { value },
    } = event;
    setColumn(value);
    setTypeText("");
  };

  const handleSalesOwnerChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedSalesOwners(value);
  };

  // Modify Fetch Button Click to Set typeText Before Fetching
  const handleFetchClick = () => {
    setTypeText(selectedSalesOwners.join(",")); // Update typeText before calling fetch
    setPageCount(1);
    setPage(0);
  };

  const searchChange = (e) => {
    setTypeText(e.target.value);
    setPageCount(1);
    setPage(0);
  };
  const clearSelectColumn = async () => {
    setIntervals(() => initialIntervals[3]);
    setColumn("");
    setTypeText("");
    setSelectedSalesOwners([]);
    fetchContact();
    setIsEmpty(true);
    afterRemoveChip();
    setSelectedContacts([]);
  };
  const getCollectionName = useSearchCollectionName(column);

  React.useEffect(() => {
    if (column?.toLowerCase() === "salesowner") {
      getSalesOwners();
    }
    if (!column) {
      setIsEmpty(true);
    }
  }, [column]); // This ensures it only fetches when 'Sales Owner' is selected.

  React.useEffect(() => {
    if (typeText) {
      setContacts([]);
      setIsLoading(true);
      setIsEmpty(false);

      const lowerColumnName = getCollectionName.toLowerCase();

      const timerId = setTimeout(() => {
        getContactSearch(lowerColumnName, typeText).then((response) => {
          const result = response.data.data
            ? response.data.data.allContacts
            : [];
          setSelectedContacts([]);
          setContacts([...result]);
          setCount(result.length);
          setIsLoading(false);
        });
      }, 1000);

      return () => {
        clearTimeout(timerId);
      };
    } else {
      setColumn("");
      setIsEmpty(true);
    }
  }, [typeText]);

  return (
    <Stack direction="row" spacing={1.3}>
      <Select
        sx={{ minWidth: "200px", maxHeight: "40px" }}
        displayEmpty
        value={column}
        onChange={handleChange}
        input={<OutlinedInput />}
        renderValue={(selected) => {
          if (isEmpty) {
            return <>Select Column</>;
          }

          return selected;
        }}
      >
        <MenuItem disabled value="">
          <>Select Column</>
        </MenuItem>
        <MenuItem value="Name">Name</MenuItem>
        <MenuItem value={companyDynamicTitle?.displayName.singularName}>
          {companyDynamicTitle?.displayName.singularName}
        </MenuItem>
        <MenuItem value="Country">Country</MenuItem>
        <MenuItem value="Date">Date</MenuItem>
        <MenuItem value="salesowner">Sales Owner</MenuItem>
      </Select>

      {column?.toLowerCase() === "salesowner" && (
        <>
          <Select
            multiple
            sx={{ minWidth: "200px", maxHeight: "40px" }}
            displayEmpty
            value={selectedSalesOwners}
            onChange={handleSalesOwnerChange}
            input={<OutlinedInput />}
            renderValue={(selected) => {
              if (!selected.length) return "Select Sales Owner";
              // Map IDs to names
              const selectedNames = selected
                .map(
                  (id) => salesOwners.find((owner) => owner._id === id)?.name
                )
                .filter(Boolean) // Remove undefined values in case of errors
                .join(", ");
              return selectedNames || "Select Sales Owner";
            }}
          >
            {salesOwners.map((owner) => (
              <MenuItem key={owner._id} value={owner._id}>
                <Checkbox
                  checked={selectedSalesOwners.indexOf(owner._id) > -1}
                />
                <ListItemText primary={owner.name} />
              </MenuItem>
            ))}
          </Select>
          <Button
            variant="contained"
            startIcon={<SearchIcon />}
            onClick={handleFetchClick}
            sx={{ minWidth: "100px", maxHeight: "40px" }}
          >
            Fetch
          </Button>
          <Button
            variant="contained"
            startIcon={<ClearIcon />}
            onClick={clearSelectColumn}
            sx={{ minWidth: "100px", maxHeight: "40px" }}
          >
            Clear
          </Button>
        </>
      )}

      {column === companyDynamicTitle?.displayName.singularName ? (
        <>
          <AutoCompleteSearch
            sx={{ minWidth: "200px", maxHeight: "40px" }}
            contactPage={contactPage}
            // lead={leads}
            // setLead={setLeads}
            searchChange={searchChange}
            column={column}
            typeText={typeText}
            setTypeText={setTypeText}
            companyDynamicTitle={companyDynamicTitle?.displayName.singularName}
          />
          {column !== "" && (
            <Button
              variant="contained"
              startIcon={<ClearIcon />}
              onClick={clearSelectColumn}
              sx={{ minWidth: "100px", maxHeight: "40px" }}
            >
              Clear
            </Button>
          )}
        </>
      ) : column === "Date" ? (
        <>
          {showChip ? (
            <div
              style={{
                minWidth: "200px",
                maxHeight: "40px",
                display: "flex",
                flexDirection: "row-reverse",
                justifyContent: "center",
              }}
            >
              <Chip
                sx={{ minWidth: "200px", maxHeight: "40px" }}
                label={`${formatCustomDate(
                  new Date(startDate).toDateString()
                )} - ${formatCustomDate(new Date(endDate).toDateString())}`}
                variant="outlined"
                onDelete={afterRemoveChip}
              />
            </div>
          ) : (
            <>
              <Autocomplete
                sx={{ minWidth: "200px", maxHeight: "40px" }}
                id="size-small-outlined"
                size="small"
                options={initialIntervals}
                getOptionLabel={(option) => option.label}
                // defaultValue={intervals[3]}
                value={intervals}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Interval"
                    placeholder="Select Interval"
                    name="interval"
                  />
                )}
                onChange={handleIntervalChange}
              />
            </>
          )}
          <ButtonGroup variant="contained" aria-label="Basic button group">
            <Button
              variant="contained"
              startIcon={<ClearIcon />}
              onClick={clearSelectColumn}
              sx={{ minWidth: "100px", maxHeight: "40px" }}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              startIcon={<SearchIcon />}
              onClick={dateFilteredContacts}
              sx={{ minWidth: "100px", maxHeight: "40px" }}
            >
              Fetch
            </Button>
          </ButtonGroup>
        </>
      ) : (
        column?.toLowerCase() !== "salesowner" && (
          <>
            <TextField
              size="small"
              sx={{ minWidth: "200px", maxHeight: "40px" }}
              inputProps={column ? "" : { readOnly: true }}
              label="Search"
              variant="outlined"
              value={typeText}
              onChange={searchChange}
              onClick={() => {
                if (!column) {
                  notify();
                }
              }}
            />
            {column !== "" && (
              <Button
                variant="contained"
                startIcon={<ClearIcon />}
                onClick={clearSelectColumn}
                sx={{ minWidth: "100px", maxHeight: "40px" }}
              >
                Clear
              </Button>
            )}
          </>
        )
      )}
    </Stack>
  );
}
