import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import * as React from "react";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { removeResource, updateTeam } from "../../services/projects.service";
// import { getUsersByRoles } from "../../services/users.service";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import SaveIcon from "@mui/icons-material/Save";
import { getResources } from "../../services/resources.service";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";

function Teams({
  projectId,
  row,
  setIsProjectFetched,
  setResource,
  project,
  currentUser,
  setIactionedApiCalled,
}) {
  const [open, setOpen] = React.useState(false);
  const [deletePopupOpen, setDeletePopupOpen] = React.useState({
    state: false,
    onSuccess: () => {},
  });

  const [isTeamFetched, setIsTeamFetched] = React.useState(true);

  const [isEditClicked, setIsEditClicked] = React.useState(false);

  const [singleResource, setSingleResource] = React.useState({
    resourceId: "",
    role: "",
    paymentCycle: "",
    _id: "",
    user: "",
    isBillable: false,
  });

  const [isPending, setIsPending] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    setDeletePopupOpen(false);
  };

  const [usersTL, setUsersTL] = React.useState([]);

  const timing = [
    { name: "Monthly", value: "MONTHLY" },
    { name: "Weekly", value: "WEEKLY" },
    { name: "Daily", value: "DAILY" },
    { name: "Hourly", value: "HOURLY" },
    { name: "Fixed", value: "FIXED" },
  ];

  const roles = [
    { name: "Project Manager", value: "PM" },
    { name: "Team Lead", value: "TL" },
    { name: "Developer", value: "DEV" },
    { name: "Quality Assurance", value: "QA" },
  ];

  React.useEffect(() => {
    handleUser();
    fetchUsersTL();
  }, []);

  const fetchUsersTL = async () => {
    try {
      // const response = await getUsersByRoles(4);
      const response = await getResources();
      if (response.data.status) {
        setUsersTL(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    if (!isTeamFetched) {
      setResource([]);
      setIsProjectFetched(false);
      console.log("use effect triggered");
      setIsTeamFetched(true);
      handleClose();
    }
  }, [isTeamFetched]);

  const removeResourceSingle = async (id, _id) => {
    console.log("Resource id", id);
    handleDeletePopupOpen(id, _id);
  };

  const handleDeletePopupOpen = (id, _id) => {
    setDeletePopupOpen({
      state: true,
      onSuccess: async () => {
        try {
          setIsPending(true);
          const response = await removeResource(projectId, id, _id);
          if (response.data.status === true) {
            toast("Resource removed successfully");
            setIsTeamFetched(false);
            setIactionedApiCalled(true);
          }
        } catch (error) {
          console.log(error);
        } finally {
          setIsPending(false);
        }
      },
    });
    return id;
  };

  const updateResource = async (id) => {
    const { isBillable, paymentCycle, resourceId, role, _id } = singleResource;
    try {
      setIsPending(true);
      const response = await updateTeam(projectId, {
        isBillable,
        paymentCycle,
        resourceId,
        role,
        _id,
      });
      if (response.data.status) {
        setIsEditClicked(!isEditClicked);
        toast("Resource successfully updated");
        setIactionedApiCalled(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsPending(false);
    }
  };

  const handleChange = (e) => {
    setSingleResource((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleUser = () => {
    let temp = { ...row };
    setSingleResource(temp);
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <Box>
            <FormControl fullWidth>
              <InputLabel id="demo-userId-select-label">
                Resource Name
              </InputLabel>
              <Select
                inputProps={{ readOnly: true }}
                labelId="demo-userId-select-label"
                id="demo-userId-select"
                label="Resource Name"
                name="resourceId"
                value={singleResource.resourceId}
              >
                {usersTL.length > 0
                  ? usersTL.map((user, index) => {
                      return (
                        <MenuItem key={index} value={user._id}>
                          {user.name}
                        </MenuItem>
                      );
                    })
                  : null}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box>
            <FormControl fullWidth>
              <InputLabel id="demo-assignedRole-select-label">
                Resource Role
              </InputLabel>
              <Select
                // disabled={toEdit}
                inputProps={{ readOnly: !isEditClicked }}
                // inputProps={
                //   isEditPage ? { readOnly: inputProps } : inputProps
                // }
                labelId="demo-assignedRole-select-label"
                id="demo-assignedRole-select"
                // value={contact.data.country}
                label="Resource Role"
                name="role"
                value={singleResource.role}
                onChange={handleChange}
              >
                {roles.length > 0
                  ? roles.map((user, index) => {
                      return (
                        <MenuItem key={index} value={user.value}>
                          {user.name}
                        </MenuItem>
                      );
                    })
                  : null}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box>
            <FormControl fullWidth>
              <InputLabel id="demo-contribution-select-label">
                Resource Time
              </InputLabel>
              <Select
                // disabled={toEdit}
                inputProps={{ readOnly: !isEditClicked }}
                // inputProps={
                //   isEditPage ? { readOnly: inputProps } : inputProps
                // }
                labelId="demo-contribution-select-label"
                id="demo-contribution-select"
                // value={contact.data.country}
                label="Resource Time"
                name="paymentCycle"
                value={singleResource.paymentCycle}
                onChange={handleChange}
              >
                {timing.length > 0
                  ? timing.map((time, index) => {
                      return (
                        <MenuItem key={index} value={time.value}>
                          {time.name}
                        </MenuItem>
                      );
                    })
                  : null}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={1.5} sx={{ display: "flex", alignItems: "center" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={singleResource.isBillable}
                onChange={({ target: { checked } = {} }) =>
                  setSingleResource((prev) => ({
                    ...prev,
                    isBillable: checked,
                  }))
                }
                disabled={!isEditClicked}
              />
            }
            label="Billable"
          />
        </Grid>
        {currentUser.role === "ADMIN" ||
        currentUser._id === project.techOwner ? (
          <>
            <Grid
              item
              xs={6}
              sm={0.75}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <>
                {!isEditClicked ? (
                  <IconButton
                    color="primary"
                    aria-label="Edit"
                    component="label"
                    onClick={() => {
                      setIsEditClicked(!isEditClicked);
                      // updateResource(singleResource._id);
                    }}
                  >
                    <ModeEditIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    color="primary"
                    aria-label="Save"
                    component="label"
                    onClick={() => {
                      updateResource(singleResource._id);
                    }}
                    disabled={isPending}
                  >
                    <SaveIcon />
                  </IconButton>
                )}
              </>
            </Grid>
            <Grid
              item
              xs={6}
              sm={0.75}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <>
                {!isEditClicked ? (
                  <IconButton
                    color="error"
                    aria-label="Delete"
                    component="label"
                    onClick={() => {
                      // handleClickOpen();
                      console.log("Inside delete click");
                      removeResourceSingle(
                        singleResource.resourceId,
                        singleResource._id
                      );
                    }}
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setIsEditClicked(false);
                      handleUser();
                    }}
                    disabled={isPending}
                  >
                    <SettingsBackupRestoreIcon />
                  </IconButton>
                )}
              </>
            </Grid>
          </>
        ) : null}
        <Grid item xs={12}>
          <div>
            <Dialog open={deletePopupOpen.state} onClose={handleClose}>
              <DialogTitle>Delete Confirmation</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Do you wish to delete this resource?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} disabled={isPending}>
                  DISAGREE
                </Button>
                <Button
                  onClick={deletePopupOpen.onSuccess}
                  disabled={isPending}
                >
                  AGREE
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default Teams;
