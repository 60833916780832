import {
  MenuItem,
  Select,
  Grid,
  InputLabel,
  FormControl,
  Box,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import Button from "@mui/material/Button";
import React from "react";
import { getUsers, getUsersByRoles } from "../../services/users.service";
import {} from "../../services/contacts.service";
import { toast } from "react-toastify";
import { addResource } from "../../services/projects.service";

import AddIcon from "@mui/icons-material/Add";
import { getResources } from "../../services/resources.service";

function CreateTeam({
  projectId,
  isEditPage,
  setCreateContactShowTimer,
  teamSingle,
  setTeamSingle,
  setIsProjectFetched,
  currentUser,
  project,
  setIactionedApiCalled,
  resource,
}) {
  const {
    status: { name },
  } = project;
  // console.log("action", action, setAction, isEditPage);
  React.useEffect(() => {
    getUsers(0, true).then((results) => {
      if (results.data.status) {
        const usernames = [];
        console.log(results.data.data, "data");
        results.data.data.forEach((user) => {
          console.log(user);
          usernames.push({ name: user.name, id: user._id });
        });
        setList([...usernames]);
        setDropdownValue(usernames[0]);
      }
      fetchUsersTL();
    });
  }, []);
  const [showTimer, setShowTimer] = React.useState(false);

  const [list, setList] = React.useState([]);
  const [dropDownValue, setDropdownValue] = React.useState(list[0]);
  const [usersTL, setUsersTL] = React.useState([]);
  const [isBillable, setIsBillable] = React.useState(false);
  const [isPending, setIsPending] = React.useState(false);

  const handleChange = (e) => {
    setTeamSingle((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  //console.log("action type", actionType);
  const timing = [
    { name: "Monthly", value: "MONTHLY" },
    { name: "Weekly", value: "WEEKLY" },
    { name: "Daily", value: "DAILY" },
    { name: "Hourly", value: "HOURLY" },
    { name: "Fixed", value: "FIXED" },
  ];

  const roles = [
    { name: "Project Manager", value: "PM" },
    { name: "Team Lead", value: "TL" },
    { name: "Developer", value: "DEV" },
    { name: "Quality Assurance", value: "QA" },
  ];
  const fetchUsersTL = async () => {
    try {
      // ** NOTE: commenting the bellow code because it is not required for now.
      // const response = await getUsersByRoles(4);
      const response = await getResources();
      if (response.data.status) {
        setUsersTL(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const ResetForm = () => {
    const temp = { ...teamSingle };
    temp._id = "";
    temp.resourceId = "";
    temp.role = "";
    temp.paymentCycle = "";
    setTeamSingle(temp);
    setIsBillable(false);
  };

  const AddResource = async () => {
    if (teamSingle.resourceId && teamSingle.role && teamSingle.paymentCycle) {
      try {
        setIsPending(true);
        let response;
        const tempObj = {};
        tempObj["team"] = [];
        const temp = { ...teamSingle, isBillable };
        delete temp._id;
        tempObj.team.push(temp);
        response = await addResource(projectId, tempObj);
        setIsProjectFetched(false);
        if (response.data.status) {
          setIactionedApiCalled(true);
          toast("Resource added successfully");
          ResetForm();
          setShowTimer(false);
          setCreateContactShowTimer(false);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsPending(false);
      }
    } else {
      toast("Please fill all the fields");
    }
  };

  return (
    <>
      <Grid container spacing={2} direction="row">
        <Grid item xs={12} sm={12}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Box>
                <FormControl fullWidth>
                  <InputLabel id="demo-userId-select-label">
                    Resource Name
                  </InputLabel>
                  <Select
                    // disabled={toEdit}
                    // inputProps={{ readOnly: true }}
                    // inputProps={isEditPage ? { readOnly: true } : inputProps}
                    labelId="demo-userId-select-label"
                    id="demo-userId-select"
                    // value={contact.data.country}
                    label="Resource Name"
                    name="resourceId"
                    value={teamSingle.resourceId}
                    onChange={handleChange}
                  >
                    {usersTL.length > 0
                      ? usersTL
                          .filter(
                            (user) =>
                              user.status === true &&
                              !!!resource.find(
                                (res) => res.resourceId === user._id
                              ) && user
                          )
                          .map((user, index) => {
                            return (
                              <MenuItem key={index} value={user._id}>
                                {user.name}
                              </MenuItem>
                            );
                          })
                      : null}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box>
                <FormControl fullWidth>
                  <InputLabel id="demo-assignedRole-select-label">
                    Resource Role
                  </InputLabel>
                  <Select
                    labelId="demo-assignedRole-select-label"
                    id="demo-assignedRole-select"
                    label="Resource Role"
                    name="role"
                    value={teamSingle.role}
                    onChange={handleChange}
                  >
                    {roles.length > 0
                      ? roles.map((user, index) => {
                          return (
                            <MenuItem key={index} value={user.value}>
                              {user.name}
                            </MenuItem>
                          );
                        })
                      : null}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box>
                <FormControl fullWidth>
                  <InputLabel id="demo-contribution-select-label">
                    Resource Time
                  </InputLabel>
                  <Select
                    labelId="demo-contribution-select-label"
                    id="demo-contribution-select"
                    label="Resource Time"
                    name="paymentCycle"
                    value={teamSingle.paymentCycle}
                    onChange={handleChange}
                  >
                    {timing.length > 0
                      ? timing.map((time, index) => {
                          return (
                            <MenuItem key={index} value={time.value}>
                              {time.name}
                            </MenuItem>
                          );
                        })
                      : null}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={1.5} sx={{ display: "flex", alignItems: "center" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isBillable}
                    onChange={({ target: { checked } = {} }) =>
                      setIsBillable(checked)
                    }
                  />
                }
                label="Billable"
              />
            </Grid>

            {!isEditPage ? (
              currentUser.role === "ADMIN" ||
              currentUser._id === project.techOwner ||
              currentUser._id === project.salesOwner ? (
                <Grid
                  item
                  xs={6}
                  sm={0.75}
                  // sx={{ display: "flex", flexDirection: "row-reverse" }}
                >
                  <Button
                    variant="contained"
                    size="large"
                    onClick={AddResource}
                    startIcon={<AddIcon />}
                    sx={{ maxHeight: "45px" }}
                    disabled={
                      name === "Closed" ? true : isPending ? true : false
                    }
                  >
                    Add
                  </Button>
                </Grid>
              ) : null
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default CreateTeam;
