import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { toast, ToastContainer } from "react-toastify";
import Page from "../UI/Page";
import { useState } from "react";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import { getActivityLogs } from "../../services/common.service";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { useNavigate } from "react-router-dom";
import { useDynamicLeadTitle } from "../../pages/Leads/Leads";
import StatsCard from "./StatsCard";
import ReportTabPanel from "./ReportTabPanel";
import SkillsetReportTabPanel from "./SkillsetReportTabPanel";
import DropReportTabPanel from "./DropReportTabPanel";
import SkillsChartTabPanel from "./SkillsChartTabPanel";

const intervals = [
  {
    label: "Last Quarter",
    value: "Last Quarter",
  },
  {
    label: "Current Quarter",
    value: "Current Quarter",
  },
  {
    label: "Current Month",
    value: "Current Month",
  },
  {
    label: "Last Month",
    value: "Last Month",
  },
  { label: "Custom", value: "Custom" },
];

const ReportAll = ({
  reportData,
  setReportData,
  handleChange,
  interval,
  setInterval,
  initialReportFigures,
  reportFigures,
  setReportFigures,
  popup,
  setPopup,
  showChip,
  setShowChip,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  handleIntervals,
  setIsSetClicked,
  page,
  setPage,
  count,
  rowsPerPage,
  handlePageChange,
  afterRemoveChip,
  customDate,
  setCustomDate,
  leadFilter,
  handleLeadFilter,
  status,
  countries,
  salesOwners,
  skills,
  dropReason,
  handleSalesOwnerForRender,
  handleSkillsForRender,
  handleDropReasonForRender,
  allChecked,
  setAllChecked,
  currentUser,
  handleOnlyOneSalesOwner,
  selectedTab,
  setSelectedTab,
}) => {
  const navigate = useNavigate();
  const leadDynamicTitel = useDynamicLeadTitle();

  const newLeads = reportFigures?.count?.newLeads || 0;
  const prospect = reportFigures?.count?.prospect || 0;
  const opportunity = reportFigures?.count?.opportunity || 0;
  const drop = reportFigures?.count?.drop || 0;
  const close = reportFigures?.count?.close || 0;

  const [changeTitle, setChangeTitle] = useState("Last Updated On");

  const showSkillsDropdown =
    selectedTab === 0 || selectedTab === 1 || selectedTab === 2;
  const showDropReasonDropdown = selectedTab !== 0 && selectedTab === 3;

  console.log(reportData, "reports detail report all");

  const isValidDate = (date) => {
    console.log(date, "reset date ");
    let dates = new Date(date);

    // Check if date is a valid Date object and its year is greater than 1000
    return dates instanceof Date && !isNaN(dates) && dates.getFullYear() > 1000;
  };

  const handleStartDateChange = (e) => {
    console.log("e", e);

    const selectedDate = new Date(e);
    if (!isValidDate(selectedDate)) {
      toast.warn("Invalid date format");
      return;
    }

    selectedDate.setHours(0, 0, 0, 0);

    if (endDate.getTime() > selectedDate.getTime()) {
      setStartDate(selectedDate);
    } else {
      toast("End date can not be less than or equal to start date");
    }
  };

  const handleEndDateChange = (e) => {
    console.log("e", e);
    const selectedDate = new Date(e);
    if (!isValidDate(selectedDate)) {
      toast.warn("Invalid date format");
      return;
    }

    selectedDate.setHours(23, 59, 59, 999);

    if (startDate.getTime() < selectedDate.getTime()) {
      setEndDate(selectedDate);
    } else {
      toast("End date can not be less than or equal to start date");
    }
  };

  const handleClose = () => {
    setPopup(false);
  };

  const handleSet = () => {
    if (!isValidDate(startDate || endDate)) {
      toast.warn("Invalid  date format");
      return;
    }
    setShowChip(true);
    handleClose();
    setCustomDate(true);
  };

  const formatCustomDate = (value) => {
    const splitValue = value.split(" ");
    splitValue.shift();
    const finalValue = splitValue.join(" ");
    return finalValue;
  };

  const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
      </Box>
    );
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setReportData([]);
    setReportFigures({
      count: initialReportFigures,
      leadCountBySkills: {},
      leadCountByReasons: {},
    });
  };

  const handleNavigateToLead = (event, id, contactId) => {
    event.stopPropagation();
    navigate(`/Leads/${id}/${contactId}`);
  };

  const reportPropsValue = {
    handleNavigateToLead,
    changeTitle,
    reportData,
    leadDynamicTitel,
    page,
    rowsPerPage,
    count,
    handlePageChange,
  };
  const skillsetReportPropsValue = {
    handleNavigateToLead,
    reportFigures,
  };

  const skillsBubblePropsValue = {
    handleNavigateToLead,
    reportFigures,
    skills,
  };

  const dropReportPropsValue = {
    handleNavigateToLead,
    reportFigures,
  };

  const tabs = [
    {
      index: 0,
      name: "Report",
      component: () => <ReportTabPanel propsValue={reportPropsValue} />,
    },
    {
      index: 1,
      name: "Skillset Report",
      component: () => (
        <SkillsetReportTabPanel propsValue={skillsetReportPropsValue} />
      ),
    },
    {
      index: 2,
      name: "Skill Bubble",
      component: () => (
        <SkillsChartTabPanel propsValue={skillsBubblePropsValue} />
      ),
    },
    {
      index: 3,
      name: "Drop Report",
      component: () => <DropReportTabPanel propsValue={dropReportPropsValue} />,
    },
  ];

  return (
    <Grid container spacing={2}>
      {/* ====================================Dropdowns and Button==================================== */}
      <Grid item xs={12}>
        <Card elevation={3} variant="outlined">
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={10}>
                <Grid container spacing={{ xs: 2, md: 1 }}>
                  <Grid item xs={12} sm={6} md>
                    {showChip ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row-reverse",
                          justifyContent: "center",
                        }}
                      >
                        <Chip
                          label={`${formatCustomDate(
                            new Date(startDate).toDateString()
                          )} - ${formatCustomDate(
                            new Date(endDate).toDateString()
                          )}`}
                          variant="outlined"
                          onDelete={afterRemoveChip}
                        />
                      </div>
                    ) : (
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-dateRange-select-label">
                          Interval
                        </InputLabel>
                        <Select
                          sx={{ maxHeight: "40px" }}
                          labelId="demo-dateRange-select-label"
                          id="demo-dateRange-select"
                          label="Interval"
                          name="interval"
                          value={interval}
                          onChange={handleChange}
                        >
                          {intervals?.map((interval, index) => {
                            return (
                              <MenuItem key={index} value={interval?.value}>
                                {interval?.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                  {/* if selected tab is 3 i.e drop report then by default status should be "Drop" */}
                  {selectedTab !== 1 && selectedTab !== 2 && (
                    <Grid item xs={12} sm={6} md>
                      {selectedTab === 3 ? (
                        <TextField
                          label="Status"
                          name="status"
                          size="small"
                          value="Drop"
                          sx={{ maxHeight: "40px" }}
                          disabled
                          fullWidth
                        />
                      ) : (
                        <FormControl fullWidth size="small">
                          <InputLabel>Status</InputLabel>
                          <Select
                            sx={{ maxHeight: "40px" }}
                            multiple
                            label="Status"
                            name="status"
                            value={leadFilter.status}
                            renderValue={(value) =>
                              value.length === status.length
                                ? ["ALL"]
                                : value.join(", ")
                            }
                            onChange={handleLeadFilter}
                          >
                            <MenuItem value="ALL">
                              <Checkbox checked={allChecked.status} />
                              <ListItemText primary="ALL" />
                            </MenuItem>
                            {status.map((item, index) => {
                              return (
                                <MenuItem key={index} value={item}>
                                  <Checkbox
                                    checked={
                                      allChecked.status
                                        ? true
                                        : leadFilter.status.indexOf(item) > -1
                                    }
                                  />
                                  <ListItemText primary={item} />
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6} md>
                    <FormControl fullWidth size="small">
                      <InputLabel>Countries</InputLabel>
                      <Select
                        sx={{ maxHeight: "40px" }}
                        multiple
                        name="country"
                        label="Countries"
                        value={leadFilter.country}
                        renderValue={(value) =>
                          value.length === countries.length
                            ? ["ALL"]
                            : value.join(", ")
                        }
                        onChange={handleLeadFilter}
                      >
                        <MenuItem value="ALL">
                          <Checkbox checked={allChecked.country} />
                          <ListItemText primary="ALL" />
                        </MenuItem>
                        {countries.map((country, index) => {
                          return (
                            <MenuItem key={index} value={country}>
                              <Checkbox
                                checked={
                                  allChecked.country
                                    ? true
                                    : leadFilter.country.indexOf(country) > -1
                                }
                              />
                              <ListItemText primary={country} />
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  {selectedTab !== 1 && selectedTab !== 2 && (
                    <Grid item xs={12} sm={6} md>
                      <FormControl fullWidth size="small">
                        <InputLabel shrink={true} id="demo-simple-select-label">
                          Sales Owner
                        </InputLabel>
                        {currentUser && currentUser.role === "ADMIN" ? (
                          <Select
                            id="demo-simple-select-label"
                            notched={true}
                            sx={{ maxHeight: "40px" }}
                            multiple
                            label="Sales Owner"
                            name="salesOwner"
                            value={leadFilter.salesOwner}
                            renderValue={(value) => {
                              return handleSalesOwnerForRender(value);
                            }}
                            onChange={handleLeadFilter}
                          >
                            <MenuItem value="ALL">
                              <Checkbox checked={allChecked.salesOwner} />
                              <ListItemText primary="ALL" />
                            </MenuItem>
                            {salesOwners.map((salesOwner, index) => {
                              if (salesOwner.active_status === true) {
                                return (
                                  <MenuItem key={index} value={salesOwner._id}>
                                    <Checkbox
                                      checked={
                                        allChecked.salesOwner
                                          ? true
                                          : leadFilter.salesOwner.includes(
                                              salesOwner._id
                                            )
                                      }
                                    />
                                    <ListItemText primary={salesOwner.name} />
                                  </MenuItem>
                                );
                              }
                            })}
                          </Select>
                        ) : (
                          <Select
                            label="Sales Owner"
                            name="salesOwner"
                            value={currentUser && currentUser.name}
                            renderValue={(value) => {
                              return [currentUser && currentUser.name];
                            }}
                            onChange={handleOnlyOneSalesOwner}
                          >
                            <MenuItem value={currentUser._id}>You</MenuItem>
                          </Select>
                        )}
                      </FormControl>
                    </Grid>
                  )}
                  {showSkillsDropdown && (
                    <Grid item xs={12} sm={6} md>
                      <FormControl fullWidth size="small">
                        <InputLabel shrink={true} id="demo-simple-select-label">
                          Skills
                        </InputLabel>
                        <Select
                          id="demo-simple-select-label"
                          notched={true}
                          sx={{ maxHeight: "40px" }}
                          multiple
                          label="Skills"
                          name="skills"
                          value={leadFilter?.skills}
                          renderValue={(value) => {
                            return handleSkillsForRender(value);
                          }}
                          onChange={handleLeadFilter}
                        >
                          <MenuItem value="ALL">
                            <Checkbox checked={allChecked?.skills} />
                            <ListItemText primary="ALL" />
                          </MenuItem>
                          {skills?.map((skill, index) => {
                            return (
                              <MenuItem key={index} value={skill?._id}>
                                <Checkbox
                                  checked={
                                    allChecked?.skills
                                      ? true
                                      : leadFilter?.skills?.includes(skill?._id)
                                  }
                                />
                                <ListItemText primary={skill?.name} />
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {showDropReasonDropdown && (
                    <Grid item xs={12} sm={6} md>
                      <FormControl fullWidth size="small">
                        <InputLabel shrink={true} id="demo-simple-select-label">
                          Drop Reason
                        </InputLabel>

                        <Select
                          id="demo-simple-select-label"
                          notched={true}
                          sx={{ maxHeight: "40px" }}
                          multiple
                          label="Drop Reason"
                          name="dropReason"
                          value={leadFilter?.dropReason}
                          renderValue={(value) => {
                            return handleDropReasonForRender(value);
                          }}
                          onChange={handleLeadFilter}
                        >
                          <MenuItem value="ALL">
                            <Checkbox checked={allChecked?.dropReason} />
                            <ListItemText primary="ALL" />
                          </MenuItem>
                          {dropReason?.map((option, index) => {
                            return (
                              <MenuItem key={index} value={option?._id}>
                                <Checkbox
                                  checked={
                                    allChecked?.dropReason
                                      ? true
                                      : leadFilter?.dropReason?.includes(
                                          option?._id
                                        )
                                  }
                                />
                                <ListItemText primary={option?.name} />
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} md={2} sx={{ textAlign: "right" }}>
                <Tooltip title="Fetch Results">
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{ height: "40px" }}
                    onClick={() => {
                      handleIntervals(customDate ? "Custom" : interval);
                      setChangeTitle(
                        leadFilter?.status?.length === 1 &&
                          leadFilter?.status[0] === "NewLead"
                          ? `Created On`
                          : `Last Updated On`
                      );
                    }}
                    startIcon={<ManageSearchIcon />}
                  >
                    FETCH
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      {/* ====================================Custom Interval Dialog=========================== */}
      <Dialog open={popup}>
        <DialogTitle textAlign="center">Define Interval</DialogTitle>
        <DialogContent>
          <Grid container spacing={2.5}>
            <Grid item xs={6} sm={6} sx={{ marginTop: "11px" }}>
              <LocalizationProvider dateAdapter={AdapterDateFns} required>
                <DesktopDatePicker
                  fullWidth
                  label="From"
                  inputFormat="MMM/dd/yyyy"
                  value={startDate}
                  maxDate={new Date().setDate(new Date().getDate() - 1)}
                  name="startDate"
                  onChange={handleStartDateChange}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={6} sm={6} sx={{ marginTop: "11px" }}>
              <LocalizationProvider dateAdapter={AdapterDateFns} required>
                <DesktopDatePicker
                  required
                  fullWidth
                  label="To"
                  inputFormat="MMM/dd/yyyy"
                  // minDate={project.timeline.deadline}
                  // minDate={}
                  value={endDate}
                  name="endDate"
                  maxDate={new Date()}
                  onChange={handleEndDateChange}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose();
              setInterval("");
              setStartDate(new Date().setDate(new Date().getDate() - 1));
            }}
            sx={{ color: "red" }}
          >
            CANCEL
          </Button>
          <Button onClick={handleSet}>SET</Button>
        </DialogActions>
      </Dialog>

      {/* =========================================Tabs======================================= */}
      <Grid item xs={12}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            {tabs?.map((tab, index) => (
              <Tab
                label={
                  <Typography sx={{ fontSize: "1.2rem" }}>
                    {tab?.name}
                  </Typography>
                }
                id={`simple-tab-${index}`}
                aria-controls={`simple-tabpanel-${index}`}
              />
            ))}
          </Tabs>
        </Box>
      </Grid>

      {/* ======================================Tab Content==================================== */}

      <Grid item xs={12}>
        <Card elevation={3} variant="outlined">
          <CardContent>
            {selectedTab === 0 && (
              <Grid container spacing={1} sx={{ pb: "5px", mt: 1 }}>
                <Grid item xs>
                  <StatsCard value={newLeads || 0} label={"New Lead"} />
                </Grid>

                <Grid item xs>
                  <StatsCard value={prospect || 0} label={"Prospect"} />
                </Grid>

                <Grid item xs>
                  <StatsCard value={opportunity || 0} label={"Opportunity"} />
                </Grid>

                <Grid item xs>
                  <StatsCard
                    label={"Drop"}
                    value={drop || 0}
                    color={drop > 0 ? "#F00" : "black"}
                  />
                </Grid>

                <Grid item xs>
                  <StatsCard
                    label={"Close"}
                    value={close || 0}
                    color={close > 0 ? "#0F0" : "black"}
                  />
                </Grid>
              </Grid>
            )}
            {reportData?.length ? (
              tabs?.map((tab, index) => (
                <CustomTabPanel
                  value={selectedTab}
                  index={tab?.index}
                  key={index}
                >
                  {selectedTab === tab?.index && tab?.component()}
                </CustomTabPanel>
              ))
            ) : (
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ textAlign: "center" }}>
                        <Typography>{`No ${leadDynamicTitel?.displayName?.singularName}
                      Records`}</Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ReportAll;
