import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

function ProjectInvolvementView({ projectInvolvementStatuses = [] }) {
  const navigate = useNavigate();
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Project Title</TableCell>
            <TableCell>Added On / Removed On</TableCell>
            <TableCell>Is Billable</TableCell>
            <TableCell>Payment Cycle</TableCell>
            <TableCell>Engagement Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {projectInvolvementStatuses.map((status) => (
            <TableRow>
              <TableCell
                component={Link}
                sx={{
                  cursor: "pointer",
                  color: "#2065D1",
                  fontWeight: 500,
                  textDecoration: "none",
                  ":hover": {
                    textDecoration: "underline",
                  },
                }}
                onClick={() => navigate(`/projects/${status?._id}`)}
              >
                {status?.title}
              </TableCell>
              <TableCell>
                {status?.addedOn
                  ? new Date(status?.addedOn).toDateString()
                  : "N/A"}
                {status?.removedOn
                  ? ` / ${new Date(status?.removedOn).toDateString()}`
                  : ""}
              </TableCell>
              <TableCell>
                {status?.isBillable ? (
                  <p style={{ color: "green" }}>YES</p>
                ) : (
                  <p style={{ color: "red" }}>NO</p>
                )}
              </TableCell>
              <TableCell>{status?.paymentCycle}</TableCell>
              <TableCell>
                {status?.removedOn ? (
                  <Tooltip title="This resource has been removed from the project as of this date">
                    <p style={{ color: "red" }}>REMOVED</p>
                  </Tooltip>
                ) : (
                  <Tooltip title="This resource has been an integral part of this project up to this point, contributing significantly to its progress and development.">
                    <p style={{ color: "green" }}>IN PROGRESS</p>
                  </Tooltip>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ProjectInvolvementView;
